import React,{useState,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate,useParams } from 'react-router-dom';
import Select from 'react-select';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
import { useDispatch,useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
// import { validateTitle } from "../../redux/crm-features/course/courseService";
import {
	addCourse,
	getCourses
} 	from "../../redux/crm-features/course/courseSlice";

const options = [
  { value: 'Salman', label: 'Salman' },
  { value: 'Asad', label: 'Asad' },
  { value: 'Hasnain', label: 'Hasnain' }
]

const initialState ={
	courseTitle : '',
	courseInstructor : '',
}

const Course = () => {

	useRedirectLoggedOutUser("/crm/login");

	const [isClearable, setIsClearable] = useState(true);
	const [isSearchable, setIsSearchable] = useState(true);
	const [loading, setLoading] = useState(false);

	const [courseData, setCourseData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {courseTitle,courseInstructor} = courseData;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { courses, course, locationClasses } = useSelector(
      (state) => state.course
  );

  console.log(courses)

  useEffect(() => {
      dispatch(getCourses());
  }, [dispatch]);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setCourseData({...courseData, [name]: value});
	}

	// const handleInputChange = (selectedOption) => {
  //   setCourseData({ ...courseData, author: selectedOption });
  // };

	const courseAdd = async (e) => {
		e.preventDefault();

		if (!courseTitle){
			return toast.error('Course Title is required')
		}

		const isDuplicateTitle = courses.some(course => course.courseTitle === courseTitle);
    if (isDuplicateTitle) {
        return toast.error('Course Title already exists');
    }

		// if (!validateTitle(courseTitle)){
		// 	return toast.error('Please enter a valid Course Title')
		// }
		// const isValid = await validateTitle(title);

		const courseData = {courseTitle,courseInstructor};
		try {
        // Dispatch addCourse and get the response
        const response = await dispatch(addCourse(courseData)).unwrap(); // Ensure unwrap() if using Redux Toolkit
        const newCourseId = response._id; // Extract the new course ID

        // console.log('New Course ID:', newCourseId); // Log the course ID to console

        // Reset form data
        setCourseData({ courseTitle: "", courseInstructor: "" });

        // Navigate to the newly created course's page
        navigate(`/crm/course/${newCourseId}`);
    } catch (error) {
        toast.error('Failed to add course. Please try again.');
        console.error('Error adding course:', error);
    }
	};

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing d-flex flex-column justify-content-center align-items-center">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseForm col-md-6 col-sm-12 col-12 m-auto">
		                        	<h3>Name your course</h3>
		                        	<p>What would you like to name your course? Don’t worry, you can always change this later.</p>
		                        	<form className="form" onSubmit={courseAdd}>
		                                <div className="row mt-4">
		                                    <div className="col-md-12">
		                                        <div className="form-group">
		                                            <input type="text" className="form-control mb-3" id="courseTitle" placeholder="Course Title" name="courseTitle" value={courseTitle} onChange={handleInputChange} />
		                                        </div>
		                                    </div>
		                                   <div className="col-md-12">
		                                        <div className="form-group">
		                                            {/*<Select
																									className="basic-single"
																									classNamePrefix="All Records"
																									defaultValue={options[0]}
																									isLoading={loading}
																									isClearable={isClearable}
																									isSearchable={isSearchable}
																									name="author"
																									value={author}
																									options={options}
																									onChange={handleInputChange}
																								/>*/}
		                                        <select value={courseInstructor} onChange={handleInputChange} name="courseInstructor" className="form-select form-control-sm mb-3">
																			     		<option>Select Instructor</option>
																			     		<option value="salman">Salman</option>
																			     		<option value="asad">Asad</option>
																			     	</select>
		                                        </div>
		                                    </div>
		                                    <div  className="col-12 mt-3">
		                                    	<input type="checkbox" id="AIExperimental" name="AIExperimental" value="AIExperimental"/>
  																				<label className="mx-2" htmlFor="AIExperimental">Help me get started with AI Experimental</label>
		                                    </div>
		                                    <div className="col-md-12 mt-3">
		                                        <div className="form-group text-end">
		                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Continue</button>
					                											<Link to="/crm/all-courses" className="btn btn btn-light-dark mx-3"> Back</Link>
		                                        </div>
		                                    </div>
		                                </div>
		                            </form>
		                        </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	
		</>
	)
}

export default Course