import React, { useState, useReducer, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import axios from "axios";

const initialState ={
  objective : '',
}

const LearningObjectivesModal = ({ course, section, show, handleClose }) => {

    const dispatch = useDispatch();
    const [sections, setSections] = useState([]);
    const [objectiveData, setObjectiveData] = useState(initialState);
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
    const {objective} = objectiveData;

  //   useEffect(() => {
  //   dispatch(getObjectiveDataSection())
  //       .then(res => {
  //           if (res.payload) {
  //               // console.log(res.payload);
  //               setSections(res.payload?.objectiveSection);
  //           }
  //       })
  //       .catch(error => {
  //           console.error("Error fetching objective from course:", error);
  //       });
  // }, [dispatch]);

    useEffect(() => {
        if (sections.length === 0) {
            addSection();
        }
    }, []);

    const addSection = () => {
        // Ensure `sections` is an array
        const updatedSections = Array.isArray(sections) ? sections : [];
        
        const newSection = {
            id: Date.now(),
            order: updatedSections.length,
            // panels: [] // Each section starts with an empty list of panels
        };

        setSections([...updatedSections, newSection]);
    };

    const confirmDeleteSec = (id) => {
        confirmAlert({
            title: "Delete This Object",
            message: "Are you sure to delete this object?",
            buttons: [
                {
                    label: "Delete",
                    onClick: () => deleteSectionLocal(id),
                },
                {
                    label: "Cancel",
                },
            ],
        });
    };

    const deleteSectionLocal = (id) => {
        setSections(sections.filter((section) => section.id !== id));
    };

    const objectiveHandler = (e, index) => {
  const { name, value } = e.target;

  // Update the specific section's title
  const updatedSections = [...sections];
  updatedSections[index] = { ...updatedSections[index], title: value };

  setSections(updatedSections);
};

const objectiveSectionAdd = async (e) => {
  e.preventDefault();

  // Validate sections and panels
  const hasEmptyFields = sections.some((section) => {
    if (!section.title || section.title.trim() === "") {
      return true; // Empty section title
    }
    return section.panels?.some((panel) => !panel.title || panel.title.trim() === ""); // Empty panel title
  });

  if (hasEmptyFields) {
    toast.error("Please fill in all fields before saving.");
    return;
  }

  try {
    // Prepare the payload to send the sections as the objective
    const payload = {
      objective: sections, // Send the `sections` data as the objective
    };

    // Make the POST request to the backend
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/addObjectiveDataSection/${course._id}/${section._id}`,
      payload
    );

    // Handle successful response
    const updatedData = response.data; // Assuming the API returns updated data
    setSections(updatedData.updatedCourse.courseSection[0].learningObjectives || []); // Update state with new learning objectives
    toast.success("Objective section added successfully!");

    // Close the modal
    handleClose();
  } catch (error) {
    // Handle errors
    toast.error("An error occurred while saving the objective section.");
    console.error("Error adding objective section:", error.response?.data || error.message);
  }
};






const handleDragStart = (event, section) => {
  event.dataTransfer.setData('section', JSON.stringify(section));
};

const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
  const newSections = [...sections];
  const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
  newSections.splice(currentIndex, 1);
  newSections.splice(targetIndex, 0, droppedItem);
  setSections(newSections);
};

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal-xl w-100"
        >
                <div className="form-group text-end">
                  <button type="submit" onClick={objectiveSectionAdd} className="m-1 btn btn-secondary">Save</button>
                  <button onClick={handleClose} className="m-1 btn btn-secondary">Close</button>
                </div>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                    <div className="courseCurriculumSec">
                    <h4>{section ? `Learning Objectives: ${section.title}` : 'Learning Objectives'}</h4>
                      <div  className="section d-flex align-items-baseline">
                       
                      <div className="newSecPanel">
                      
                      <div className="panels">
                          {sections?.map((section, i) => (
                            <div key={section.id || section._id} className="panel d-flex align-items-baseline" draggable
                            onDragStart={(event) => handleDragStart(event, section)}
                            onDragOver={(event) => handleDragOver(event)}
                            onDrop={(event) => handleDrop(event, i)}>
                            <div>
                              <button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
                            </div>
                            <div className="newLessonPanel d-flex align-items-center justify-content-between">
                          <div className="col-10">
                          
                              <form className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="col-12 row">
                                <div className="form-group col-10">
                                    <input type="text" className="form-control" placeholder="New Object" value={section.title} name={`objective-${i}`} onChange={(e) => objectiveHandler(e, i)}/>
                                </div>                                
                              </div>
                              </form>
                              </div>
                              <div className="newSecPanelDropDown">
                              {['left'].map(
                                (direction) => (
                                  <DropdownButton
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    drop={direction}
                                    title={<i className="fa fa-ellipsis-v"></i>}
                                  >
                                    {/* Conditionally render Remove or Delete Location */}
                                    {section._id ? (
                                        <li onClick={() => confirmDeleteSec(section._id)}>
                                            <span>Delete Object</span>
                                        </li>
                                    ) : (
                                        <li onClick={() => deleteSectionLocal(section.id)}>
                                            <span>Remove Object</span>
                                        </li>
                                    )}
                                    
                                  </DropdownButton>
                                ),
                              )}
                          </div>
                        </div>
                            </div>
                          ))}
                        </div>
                        <div className="newSecPanelBottom">
                          <ul>
                            <li onClick={addSection}>
                              <i className="fa fa-plus-circle"></i>
                              <span>New Object</span>
                            </li>
                            {/*<li>
                              <span>Bulk upload</span>
                            </li>
                            <li>
                              <span>Location summary</span>
                            </li>*/}
                          </ul>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={objectiveSectionAdd} className="btn btn-secondary">Save</button>
                <button onClick={handleClose} className="btn btn-secondary">Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default LearningObjectivesModal;
