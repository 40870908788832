import React, { useState,useReducer,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";

const CourseDocsModal = ({ course, section, show, handleClose }) => {

    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
    const [selectedOption, setSelectedOption] = useState('');
    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        const fileList = e.target.files;
        setFiles(fileList);
    };

    const uploadFileToS3 = async (e) => {
        e.preventDefault();

        if (!selectedOption || files.length === 0) {
          toast.error('Please select a title and upload at least one file.');
          return;
        }

        const formData = new FormData();
        formData.append('title', selectedOption);

        for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i]);
        }

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/upload-documents/${course._id}/${section._id}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          toast.success('Successfully Uploaded');
          setFiles([]); // Clear file input after successful upload
          setSelectedOption(''); // Clear title selection
          handleClose();
        } catch (error) {
          toast.error(error.response ? error.response.data.message : 'Server error');
        }

    };

    const generateS3Url = (url) => {
      const generatedUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/uploads/${url}`;
      // console.log("Generated URL:", generatedUrl);
      return generatedUrl;
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="textImageModal modal w-100"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>{section ? `Upload Documents for: ${section.title}` : 'Upload Documents'}</h4>
                </Modal.Title>
                <span onClick={handleClose}>
                    <i className="fa fa-times"></i>
                </span>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* Picklist Field */}
                    <div className="form-group mb-3">
                        <label htmlFor="picklistField">Select Option</label>
                        <select
                            id="picklistField"
                            className="form-control"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                        >
                            <option value="">Select an Option</option>
                            <option value="Intro">Intro</option>
                            <option value="Audit Trail">Audit Trail</option>
                            <option value="VAT Report">VAT Report</option>
                        </select>
                    </div>

                    {/* File Upload Field */}
                    <div className="form-group mb-3">
                        <label htmlFor="fileUpload">Upload File</label>
                        <input
                            type="file"
                            id="fileUpload"
                            className="form-control"
                            onChange={handleFileChange}
                            accept="*"
                            multiple
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group text-end">
                    <button onClick={uploadFileToS3} type="button" className="m-1 btn btn-secondary">
                        Update
                    </button>
                    <button onClick={handleClose} className="m-1 btn btn-secondary">
                        Close
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseDocsModal;
