import React, { useState,useReducer,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";

const CourseViewDocsModal = ({ course, section, show, handleClose }) => {

    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
    const generateS3Url = (url) => {
      const generatedUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/uploads/${url}`;
      // console.log("Generated URL:", generatedUrl);
      return generatedUrl;
    };

    const handleDeleteMedia = async (documentId, mediaId) => {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/course/crm/delete-media/${course._id}/sec/${section._id}/doc/${documentId}/media/${mediaId}`);
        if (response.status === 200) {
          // Update UI or re-fetch course data
          toast.success(response.data.message);
          forceUpdate();
        }
      } catch (error) {
        toast.error(error.response?.data?.error || 'Failed to delete media');
      }
    };

    return (
       <>
       {/*<!-- Modal -->*/}
            <Modal show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4><span className="text-capitalize">{section.title}</span> Documents</h4>
                    </Modal.Title>
                    <span onClick={handleClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body className="overflow-auto" style={{ height: '100vh' }}>
                            <div>
                              {section?.documents?.length > 0 ? (
                                section.documents.map((doc, docIndex) => (
                                  <div key={docIndex} className="mb-4" id={doc._id}>
                                    <h4 className="bg-light">{doc.title}</h4>
                                    {doc.media?.length > 0 ? (
                                      doc.media.map((file, fileIndex) => {
                                        const fileType = file.type.toLowerCase(); // Convert type to lowercase for comparison
                                        return (
                                          <div key={fileIndex} className="mb-2" id={file._id}>
                                              <div className="d-flex justify-content-between align-items-center py-3 my-3 border-bottom">
                                                  <p className="mb-0">File Name: {file.filename}</p>
                                                  {/* Delete button */}
                                                  <button onClick={() => handleDeleteMedia(doc._id, file._id)} className="btn btn-danger btn-sm">Delete</button>
                                              </div>
                                            {file.type === 'IMAGE' ? (
                                              <img
                                                src={generateS3Url(file.url)}
                                                alt={file.filename}
                                                style={{ maxWidth: '100%', height: 'auto' }}
                                              />
                                            ) : (file.type === 'pdf' || file.type === 'PDF') ? (
                                              <iframe
                                                src={generateS3Url(file.url)}
                                                title={file.filename}
                                                style={{ width: '100%', height: 'calc(100vh - 100px)' }}
                                              ></iframe>
                                            ) : file.type === 'VIDEO' ? (
                                              <video controls style={{ maxWidth: '100%' }}>
                                                <source src={generateS3Url(file.url)} type="video/mp4" />
                                                Your browser does not support the video tag.
                                              </video>
                                            ) : (
                                              <a href={generateS3Url(file.url)} target="_blank" rel="noopener noreferrer">
                                                {file.filename}
                                              </a>
                                            )}

                                          </div>

                                        );
                                      })
                                    ) : (
                                      <p>No media available</p>
                                    )}
                                  </div>
                                ))
                              ) : (
                                <p>No documents uploaded</p>
                              )}
                            </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group text-end">
                        <button onClick={handleClose} className="m-1 btn btn-secondary">Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
       </>
    );
};

export default CourseViewDocsModal
