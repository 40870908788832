import React,{useState,useEffect} from "react";
import { Link,useParams } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import Footer from "../components/Footer";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { VscPreview } from "react-icons/vsc";
import { PiNotebook } from "react-icons/pi";
import { CgTranscript } from "react-icons/cg";
import { RiSendPlaneLine } from "react-icons/ri";
import { Button, Container, Row, Col, Table,Form } from 'react-bootstrap';
import {FaArrowAltCircleRight, FaChevronRight} from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiSettings } from 'react-icons/fi'; // Import a settings icon from react-icons

import {
  selectCourse,
  getCourses,
  getCourse,
}   from "../../redux/crm-features/course/courseSlice";
/*const docs = [
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4",
        fileType: "video/mp4",
        fileName: "SampleVideo_1280x720_10mb.mp4",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf",
        fileType: "application/pdf",
        fileName: "Registration+Process+Sage+Awards.pdf",
    },
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx",
        fileType: "xlsx",
        fileName: "Schedule.xlsx",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Dickinson_Sample_Slides.pptx",
        fileType: "pptx",
        fileName: "Dickinson_Sample_Slides.pptx",
    },
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png",
        fileType: "png",
        fileName: "ufqekd9f.png",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx",
        fileType: "docx",
        fileName: "Developer+job+Posting.docx",
    },
    //{ uri: require("./example-files/pdf.pdf") }, // Local File
];*/

// const Slider = ({ items }) => {
//     const [index, setIndex] = useState(0);

//     const handlePrevious = () => {
//         setIndex(prevIndex => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
//     };

//     const handleNext = () => {
//         setIndex(prevIndex => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
//     };

//     return (
//         <Container>
//             <Row>
//                 <Col className="text-right mb-3">
//                     <div className="d-flex align-items-center justify-content-between">
//                         <h4 className="fw-bold align-self-start">Sage50_introduction</h4>
//                         <div className="" style={{ marginTop: '-10px' }}>
//                             <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handlePrevious}><FaArrowAltCircleLeft/></span>
//                             <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handleNext}><FaArrowAltCircleRight/></span>
//                         </div>
//                     </div>
//                 </Col>
//             </Row>
//             <Row>
//                 {items.map((item, idx) => (
//                     <Col key={idx} style={{ display: index === idx ? 'block' : 'none' }}>
//                         {item}
//                     </Col>
//                 ))}
//             </Row>
//         </Container>
//     );
// };

const CoursesView = () => {

    useRedirectLoggedOutUser("/crm/login");

    const { id } = useParams();
    const dispatch = useDispatch();
    const { courses, course } = useSelector(
      (state) => state.course
    );

    useEffect(() => {
      dispatch(getCourse(id));
    }, [dispatch, id]);

    // console.log(course)

    /*disable right click context menu*/
    // useEffect(() => {
    //     const disableRightClick = (event) => {
    //         if (event.button === 2) {
    //             event.preventDefault();
    //             //alert("Right-click context menu is disabled.");
    //         }
    //     };

    //     document.body.addEventListener('contextmenu', disableRightClick);

    //     return () => {
    //         document.body.removeEventListener('contextmenu', disableRightClick);
    //     };
    // }, []);
    /*disable right click context menu*/
    // const items = [
    //     <video controls width="100%" height="600px" className="videoPlayer">
    //         <source src="https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4"></source>
    //     </video>,
    //     //<DisableLeftClickIframe width="100%" height="600px" src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf" />,
    //         <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
    //                 src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf"></iframe>,
    //     <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px" frameBorder="0" scrolling="no"
    //             src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx&waccluster=PIE1"></iframe>,
    //     <iframe style={{pointerEvents:'none',userSelect:'none'}} src="https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fcrmone%2Es3%2Eus%2Deast%2D2%2Eamazonaws%2Ecom%3A443%2Fuploads%2FDickinson%5FSample%5FSlides%2Epptx&amp;wdAr=1.3333333333333333&amp;Embed=1&amp;hideControls=true"
    //         width="100%" height="600px">
    //     </iframe>,
    //     <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
    //             src="https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png"></iframe>,
    //     <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
    //             src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx&embedded=true">
    //     </iframe>
    // ];



    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedPanel, setSelectedPanel] = useState(null);
    const [selectedMediaId, setSelectedMediaId] = useState(null);

    const items = selectedPanel?.media || [];

    // Handle navigation based on _id
    const handlePrevious = () => {
        if (items.length > 0) {
            const currentIndex = items.findIndex((item) => item._id === selectedMediaId);
            const previousIndex = (currentIndex === 0 ? items.length - 1 : currentIndex - 1);
            setSelectedMediaId(items[previousIndex]._id);
        }
    };

    const handleNext = () => {
        if (items.length > 0) {
            const currentIndex = items.findIndex((item) => item._id === selectedMediaId);
            const nextIndex = (currentIndex === items.length - 1 ? 0 : currentIndex + 1);
            setSelectedMediaId(items[nextIndex]._id);
        }
    };

    // Ensure `selectedMediaId` is set whenever the panel changes
    useEffect(() => {
        if (items.length > 0) {
            setSelectedMediaId(items[0]._id); // Set the first item's _id as default
        } else {
            setSelectedMediaId(null); // Clear selection if no items
        }
    }, [selectedPanel]);

    // useEffect(() => {
    //     if (selectedSection?.panel) {
    //         setSelectedPanel(selectedSection.panel); // Set panel if it exists
    //     } else {
    //         setSelectedPanel(null); // Clear panel if none exists
    //     }
    // }, [selectedSection]);
    function handleOptionClick(option, section) {
        console.log(`${option} clicked for section ${section.title}`);
        // Add your logic to handle the click on Objectives or Documents
    }
    const generateS3Url = (url) => {
  const generatedUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/uploads/${url}`;
  // console.log("Generated URL:", generatedUrl);
  return generatedUrl;
};

    return (
        <>
            {/*<!--  BEGIN MAIN CONTAINER  -->*/}
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>

                <div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0 }}>
                    <Sidebar style={{width:"320px"}} className="custom_sidebar">
                        <h5 className="fw-bold pb-2 px-4 border-bottom text-capitalize">{course && (<>{course.courseTitle}</>) }</h5>
                        {/*<Menu>
                            <SubMenu label="Starlite_Bookkeeping" title="Starlite_Bookkeeping" className="border-bottom">
                                <MenuItem title="Sage50_Introduction" component={<Link to="/crm" className="link" />}> Sage50_Introduction </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Inhouse outsourced perspective </MenuItem>
                            </SubMenu>
                            <SubMenu label="Business Orientation and VAT Class" className="border-bottom" >
                                <MenuItem component={<Link to="/crm" className="link" />}>  Business Orientation and VAT Class_Presentation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> VAT Class_Presentation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Business Orientation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Sage Backup </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Intro to Xero </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Business Tax</MenuItem>
                            </SubMenu>
                            <SubMenu label="Top Handy_Bookkeeping" className="border-bottom">
                                <MenuItem component={<Link to="/crm" className="link" />}> Top Handy Company Background </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Top Handy_Processing Invoices under Reverse Charge </MenuItem>
                            </SubMenu>
                        </Menu>*/}
                        <Menu>
                            {course?.courseSection?.length > 0 ? (
                                course.courseSection.map((section) => (
                                    <div key={section?.id} className="submenu-container d-flex">
                                        <SubMenu
                                            label={section?.title}
                                            title={section?.title}
                                            className={`border-bottom ${selectedSection?.id === section?.id ? "active-menu-item" : ""}`}
                                            onClick={() => { setSelectedSection(section); setSelectedPanel(null); }}
                                        >
                                            {section?.panels?.length > 0 ? (
                                                section.panels.map((panel) => (
                                                    <MenuItem
                                                        key={panel?.id}
                                                        title={panel?.title}
                                                        onClick={() => {
                                                            setSelectedPanel(panel);
                                                            setSelectedSection(section);
                                                        }}
                                                        className={selectedPanel?.id === panel?.id ? "active-menu-item" : ""}
                                                    >
                                                        {panel?.title}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem key="no-panel" title="No panels available">
                                                    No panels available
                                                </MenuItem>
                                            )}
                                        </SubMenu>

                                        {/* Settings Icon and Dropdown */}
                                        {/*<div className="settings-icon">
                                            <DropdownButton
                                                id={`dropdown-${section?.id}`}
                                                title={<FiSettings />}
                                                variant="secondary"
                                                align="end"
                                            >
                                                <Dropdown.Item onClick={() => handleOptionClick('Objectives', section)}>Objectives</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleOptionClick('Documents', section)}>Documents</Dropdown.Item>
                                            </DropdownButton>
                                        </div>*/}
                                    </div>
                                ))
                            ) : (
                                <p>No course sections available</p>
                            )}
                        </Menu>
                    </Sidebar>

                </div>

                {/*<!--  BEGIN CONTENT AREA  -->*/}
                <div id="content" className="main-content px-lg-5">
                    <div className="p-3 mb-2 d-flex flex-column justify-content-center align-items-center">

                        <div className="middle-content container px-3" style={{maxHeight:'670px',overflow:'auto'}}>

                            <div>
                                 <Container>
                                    <Row>
                                        <Col className="text-right mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                {/*<h6 className="fw-bold align-self-start">
                                                    {decodeURIComponent(items.find((item) => item._id === selectedMediaId)?.url.split("/").pop() || "Default File Name")}
                                                </h6>*/}
                                                <div className="secPanelTitle d-flex align-items-baseline">
                                                {selectedSection && (<>
                                                    <h5 className="mx-1">{selectedSection?.title || "Default Section Title"}</h5>
                                                    <FaChevronRight className="fs-6" size="10"/>
                                                </>)}
                                                    {selectedPanel && (<><h5 className="text-muted mx-1">{selectedPanel?.title || "Default Panel Title"}</h5></>)}
                                                </div>
                                                <div className="" style={{ marginTop: '-10px' }}>
                                                    <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handlePrevious}><FaArrowAltCircleLeft/></span>
                                                    <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handleNext}><FaArrowAltCircleRight/></span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {items.length > 0 ? (
                                            items.map((item, idx) => (
                                                <Col key={item._id} style={{ display: selectedMediaId === item._id ? "block" : "none" }}>
                                                    {(item.type === "video" || item.type === "VIDEO") && (
                                                        <video controls width="100%" height="600px" className="videoPlayer">
                                                            <source src={generateS3Url(item.url)} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                    {(item.type === "image" || item.type === "IMAGE") && (
                                                        <img
                                                            src={generateS3Url(item.url)}
                                                            alt={`Media ${item._id}`}
                                                            width="100%"
                                                            height="600px"
                                                            style={{ objectFit: "contain" }}
                                                        />
                                                    )}
                                                     {(item.type === "pdf" || item.type === "PDF") && (
                                                        <iframe
                                                            src={generateS3Url(item.url)}
                                                            title={`File ${item._id}`}
                                                            width="100%"
                                                            height="600px"
                                                            style={{ border: "none" }}
                                                        >
                                                            Your browser does not support iframes.
                                                        </iframe>
                                                    )}
                                                    {/* Add other conditions for different media types */}
                                                </Col>
                                            ))
                                        ) : (
                                            <Col style={{backgroundColor:'#f5f5f5'}}>
                                                <p className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: course?.courseOverview }}>
                                                    {/*{course?.courseOverview}*/}
                                                </p>
                                            </Col>
                                        )}
                                    </Row>
                                </Container>
                            </div>
                            {/*<DocViewer style={{width: "auto",minHeight: "580px", maxHeight:"630px"}} documents={docs} pluginRenderers={DocViewerRenderers} />

                            <video controls width="100%" height="600px" className="videoPlayer">
                                <source src="https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4"></source>
                            </video>
                            <iframe width="100%" height="600px" src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf"></iframe>

                            <iframe width="100%" height="600px" frameBorder="0" scrolling="no"
                                    src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx&waccluster=PIE1"></iframe>

                            <iframe
                                src="https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fcrmone%2Es3%2Eus%2Deast%2D2%2Eamazonaws%2Ecom%3A443%2Fuploads%2FDickinson%5FSample%5FSlides%2Epptx&amp;wdAr=1.3333333333333333&amp;Embed=1&amp;hideControls=true"
                                width="100%" height="600px">
                            </iframe>

                            <iframe width="100%" height="600px" style={{objectFit:'contain'}} src="https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png"></iframe>

                            <iframe width="100%" height="600px"
                                src="https://docs.google.com/gview?url=https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx&embedded=true">
                            </iframe>*/}
                        </div>
                    </div>
                    <div className="col-12 py-3">
                        <p className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: course?.courseSection?.panels?.content }}></p>

                    </div>
                    <div className="p-3 mb-4 d-flex flex-column align-items-center">
                        <div className="middle-content container-xxl p-0">
                            <div className="simple-tab">
                                <ul className="nav nav-tabs justify-content-center align-items-center" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="overview-tab" data-bs-toggle="tab"
                                                data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">
                                            <span className="fs-4 text-muted mx-2"><VscPreview /></span>Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="transcript-tab" data-bs-toggle="tab"
                                                data-bs-target="#TrainingSequence-tab-pane" type="button" role="tab" aria-controls="TrainingSequence-tab-pane" aria-selected="false">
                                            <span className="fs-4 text-muted mx-2"><CgTranscript /></span>Training Sequence</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="QandA-tab" data-bs-toggle="tab"
                                                data-bs-target="#QandA-tab-pane" type="button" role="tab" aria-controls="QandA-tab-pane" aria-selected="false">
                                            <span className="fs-4 text-muted mx-2"><RiSendPlaneLine /></span>Q & A</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="notebook-tab" data-bs-toggle="tab"
                                                data-bs-target="#notebook-tab-pane" type="button" role="tab" aria-controls="notebook-tab-pane" aria-selected="false" disabled>
                                            <span className="fs-4 text-muted mx-2"><PiNotebook /></span>Notebook</button>
                                    </li>
                                </ul>
                                <div className="tab-content container" id="myTabContent">
                                    <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex={0}>
                                        <div className="row pt-4">
                                            <div className=" style-4">
                                                <p className="text-muted">INSTRUCTOR</p>
                                                <div className="media mt-0 mb-3 pt-3">
                                                    <div className="avatar avatar-md avatar-indicators me-3">
                                                        <img alt="avatar" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                                             className="rounded-circle"/>
                                                    </div>
                                                    <div className="media-body">
                                                        <h4 className="media-heading mb-0">{course?.courseInstructor}</h4>
                                                        <p className="media-text">Training Manager</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row pt-4">
                                            <h6 className="fs-4 fw-bold">Course details</h6>
                                            <div className="text-muted">
                                                <span className="px-1">{course?.courseSetting[0]?.courseTotalHours}<span className="px-1">-</span></span>
                                                <span className="px-1">{course?.courseSetting[0]?.courseLevel}<span className="px-1">-</span></span>
                                                <span className="px-1">Released: {moment(course?.createdAt).format("DD MMM")}</span>
                                            </div>
                                            <p className="mt-3" dangerouslySetInnerHTML={{ __html: course?.courseOverview }}>
                                            {/*{course?.courseOverview}*/}
                                            </p>
                                        </div>
                                        <div className="row pt-4">
                                            <h6 className="fs-4 fw-bold">Skills covered</h6>
                                            <div className="p-2">
                                                <span className="badge badge-light-dark mb-2 me-4">{course?.courseSkills}</span>
                                                {/*<span className="badge badge-light-dark mb-2 me-4">Payroll</span>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="TrainingSequence-tab-pane" role="tabpanel" aria-labelledby="transcript-tab" tabIndex={0}>

                                        <div className="my-5 overflow-auto">
                                            <h6 className="fs-4 my-3">Training Sequence for <span className="text-decoration-underline">{course?.courseTitle}</span></h6>
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>Section Title</th>
                                                    <th>Task</th>
                                                    <th>Industry</th>
                                                    <th>Software</th>
                                                    <th>Hours</th>
                                                    <th style={{ textAlign: 'center' }}>Achieved</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {course?.courseSection?.length > 0 ? (
                                                    course.courseSection.map((section) => (
                                                        <React.Fragment key={section.id}>
                                                            {section.trainingSequence && section.trainingSequence.length > 0 ? (
                                                                section.trainingSequence.map((training, index) => (
                                                                    <tr key={index}>
                                                                        {index === 0 && (
                                                                            <td rowSpan={section.trainingSequence.length}>{section.title}</td>
                                                                        )}
                                                                        <td>{training.task}</td>
                                                                        <td>{training.industry}</td>
                                                                        <td>{training.software}</td>
                                                                        <td>{training.hours}</td>
                                                                        <td key={section._id} style={{ textAlign: 'center' }}>
                                                                            <Form.Check key={section._id} id={section._id} type="checkbox" />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr key={`${section.id}-no-training`}>
                                                                    <td>{section.title}</td>
                                                                    <td colSpan="5">No training sequence available</td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">No course sections available</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="QandA-tab-pane" role="tabpanel" aria-labelledby="QandA-tab" tabIndex={0}>
                                        <div className="row pt-4" dangerouslySetInnerHTML={{ __html: course?.courseQnA }}>
                                            {/*<h6 className="fs-4 fw-bold">What are the Financial Statements?</h6>
                                            <p className="mb-4">The financial statements are the three primary reports that summarise a company’s financial position, results of operations and cash flows. The accounting statements are the balance sheet, profit and loss (income statement), and cash flow statement.</p>
                                            <h6 className="fs-4 fw-bold">What is the Easiest Way to Produce the Financial Statements?</h6>
                                            <p className="mb-4">Using accounting software is by far the easiest way to process your transactions and prepare financial statements. The software will automate many tasks for you, such as recording transactions, producing reports, and calculating totals. This leaves you more time to focus on running your business.</p>*/}
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="notebook-tab-pane" role="tabpanel" aria-labelledby="notebook-tab" tabIndex={0}>
                                        <p className="mt-3">Using accounting software is by far the easiest way to process your transactions and prepare financial statements. The software will automate many tasks for you, such as recording transactions, producing reports, and calculating totals. This leaves you more time to focus on running your business.</p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <Footer />

                </div>
                {/*<!--  END CONTENT AREA  -->*/}

            </div>
            {/*<!-- END MAIN CONTAINER -->*/}
        </>
    )
}

export default CoursesView;