import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import authService from "../../redux/crm-features/auth/authService";

const useRedirectLoggedOutUser = (path) => {

	const navigate = useNavigate();

	useEffect(() =>{

		let isLoggedIn;

		const redirectLoggedOutUser = async () =>{
			try{
				isLoggedIn = await authService.getLoginStatus();
			}catch(error){
				console.log(error.message);
			}

			if (!isLoggedIn) {
		        toast.info("Session expired, please login to continue");
		        navigate(path);
		        return;
		    }
		};
		redirectLoggedOutUser();
	},[path, navigate]);

	// useEffect(() => {
	// 	const redirectLoggedOutUser = async () => {
	// 		try {
	// 			const response = await authService.getLoginStatus();

	// 			// Check if login status is unauthorized
	// 			if (!response.success) {
	// 				throw new Error(response.message || "Session expired");
	// 			}
	// 		} catch (error) {
	// 			console.error(error.message);
	// 			// Show toast notification for expired session
	// 			toast.info("Session expired, please login to continue");
	// 			// Redirect to login page
	// 			navigate(path);
	// 		}
	// 	};

	// 	redirectLoggedOutUser();
	// }, [path, navigate]);

};

export default useRedirectLoggedOutUser