import React,{useState,useRef,useEffect,useMemo,useReducer} from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Breadcrumb from '../components/Breadcrumb';
import { toast } from "react-toastify";
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import {MaterialReactTable} from 'material-react-table';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getCourses,
  getCourse,
  upgradeCourse,
  deleteCourse,
  addLocationClassSection,
  getLocationClassSection,
  updateLocationClassSection
}   from "../../redux/crm-features/course/courseSlice";
import CourseFormModal from "./CourseFormModal";

// const data = [
//   {
//     id: '1',
//     image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
//     name: 'Book 1',
//     author: 'Author 1',
//     createdDate: '2023-01-15',
//     sales: 100,
//     status: 'Active',
//     enrollments: 50,
//   },
//   {
//     id: '2',
//     image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
//     name: 'Book 2',
//     author: 'Author 2',
//     createdDate: '2022-11-20',
//     sales: 80,
//     status: 'Inactive',
//     enrollments: 30,
//   },
//   {
//     id: '3',
//     image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
//     name: 'Book 3',
//     author: 'Author 3',
//     createdDate: '2024-02-10',
//     sales: 120,
//     status: 'Active',
//     enrollments: 70,
//   },
//   // Add more data objects as needed
// ];

// const options = [
//   { value: 'Salman', label: 'Salman' },
//   { value: 'Asad', label: 'Asad' },
//   { value: 'Hasnain', label: 'Hasnain' }
// ]

// const classes = [
//     { label: "Demo1", value: "Demo1" },
//     { label: "Demo2", value: "Demo2" },
//     { label: "Demo3", value: "Demo3" },
//     { label: "Demo4", value: "Demo4" }
//   ];

const initialState ={
  locationSection : '',
  classSection : '',
}

const AllCourses = (props) => {

  useRedirectLoggedOutUser("/crm/login");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationClassData, setLocationClassData] = useState(initialState);
  const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
  const {locationSection, classSection} = locationClassData;

  const [ViewLocationModal, SetLocationModalShow] = useState(false)
  const handleLocationModalShow = () => { SetLocationModalShow(true) }
  const hanldeLocationModalClose = () => { SetLocationModalShow(false) }

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [loading, setLoading] = useState(false);

  const [value, setvalue] = useState("");
  const handleOnchange = (val) => setvalue(val);

  const { courses, course, locationClasses } = useSelector(
      (state) => state.course
  );
  const filteredCourses = Array.isArray(courses)
        ? courses.filter((course) => course.deletedAt === null)
        : [];
  // console.log(filteredCourses)

  useEffect(() => {
      dispatch(getCourses());
  }, [dispatch]);

  // const removeCourse = async (id) => {
  //     await dispatch(deleteCourse(id));
  //     dispatch(getCourses());
  // };

  const removeCourse = async (id) => {
    try {
        // Call the backend API to soft delete the course by updating deletedAt
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/course/crm/soft-delete/${id}`);
        dispatch(getCourses());
        // Show success message
        toast.success('Course successfully deleted (soft delete)');

        // Optionally, dispatch a Redux action to update the local state
        // dispatch(fetchCourses()); // Update the list after deletion
    } catch (error) {
        toast.error('Failed to delete course');
        console.error('Error deleting course:', error);
    }
};

  const confirmDelete = (id) => {
      confirmAlert({
        title: "Delete This Course",
        message: "Are you sure to do delete this course?",
        buttons: [
          {
            label: "Delete",
            onClick: () => removeCourse(id),
          },
          {
            label: "Cancel",
            // onClick: () => alert("Click No"),
          },
        ],
      });
  };

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => <> <span><img src={row.courseThumbnail} style={{width : '100px'}}/></span></>, //alternate way
        id: 'courseThumbnail', //id required if you use accessorFn instead of accessorKey
        header: 'Thumbnail',
        Header: () => <span>Thumbnail</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseTitle, //alternate way
        id: 'courseTitle', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className="d-flex align-items-center"><Link to={`/crm/course-detail/${row.original._id}`}><b>{renderedCellValue}</b></Link>
            <div className="customDropDownButton">
              {['end'].map(
                (direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    title={`...`}
                  >
                    {/*<li><Link className="dropdown-item" onClick={() => {handleCourseFormModalShow()}}>Click me</Link></li>*/}
                    <li><Link className="dropdown-item" >Publish Course</Link></li>
                    <li><Link className="dropdown-item">
                    <CourseFormModal 
                    _id={row.original._id} 
                    courseLevel={row.original?.courseSetting?.[0]?.courseLevel || ''}
                    courseType={row.original?.courseSetting?.[0]?.courseType || ''}
                    courseAccess={row.original?.courseSetting?.[0]?.courseAccess || ''}
                    courseLocation={row.original?.courseSetting?.[0]?.courseLocation || ''}
                    courseDuration={row.original?.courseSetting?.[0]?.courseDuration || ''}
                    courseFee={row.original?.courseSetting?.[0]?.courseFee || ''}
                    courseDiscount={row.original?.courseSetting?.[0]?.courseDiscount || ''}
                    courseTotalHours={row.original?.courseSetting?.[0]?.courseTotalHours || ''}
                    courseClasses={row.original?.courseSetting?.[0]?.courseClasses || ''}
                    />
                    </Link></li>
                    <li><Link className="dropdown-item" >Duplicate Course</Link></li>
                  </DropdownButton>
                ),
              )}
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.courseInstructor, //alternate way
        id: 'courseInstructor', //id required if you use accessorFn instead of accessorKey
        header: 'Author',
        Header: () => <span>Author</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createdAt, //alternate way
        id: 'createdAt', //id required if you use accessorFn instead of accessorKey
        header: 'Creation Date',
        Header: () => <span>Creation Date</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseSales, //alternate way
        id: 'courseSales', //id required if you use accessorFn instead of accessorKey
        header: 'Sales',
        Header: () => <span>Sales</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.coursePublished, //alternate way
        id: 'coursePublished', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseEnrollments, //alternate way
        id: 'courseEnrollments', //id required if you use accessorFn instead of accessorKey
        header: 'Enrollments',
        Header: () => <span>Enrollments</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span>
                            <FaTrashAlt
                              onClick={() => confirmDelete(row._id)}
                              size={18}
                              cursor="pointer"
                              color="rgb(0,0,0,.8)"
                            />
                            &nbsp;
                            {/*<span to={`/crm/course-detail/${row._id}`}>
	                            <AiFillEdit
	                              size={20}
	                              cursor="pointer"
	                              color="rgb(0,0,0,.8)"
	                            />
                            </span>*/}
                          </span>, //alternate way
        id: 'action', //id required if you use accessorFn instead of accessorKey
        header: 'Action',
        Header: () => <span>Action</span>, //optional custom header render
      },
    ],
    [],
  );

  const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

  // const [courseLevel, setCourseLevel] = useState('');
  // const [showField, setShowField] = useState(false);

  // const handleSelectChange = (event) => {
  //   const selectedOption = event.target.value;
  //   setCourseLevel(selectedOption);

  //   // If 'Online' is selected, set showField to true
  //   setShowField(selectedOption === 'center');
  // };

  // const [ipAddress, setIpAddress] = useState('');

  // useEffect(() => {
  //   fetch('https://api.ipify.org?format=json')
  //     .then(response => response.json())
  //     .then(data => setIpAddress(data.ip))
  //     .catch(error => console.error('Error fetching IP address:', error));
  // }, []);

  const [sections, setSections] = useState([]);
  const [secondSections, setSecondSections] = useState([]);

  useEffect(() => {
      if(sections.length===0) {
        addSection();
      } else {

      }
      if(secondSections.length===0) {
        addSecondSection();
      } else {

      }
  }, []);

  // useEffect(() => {
  //     dispatch(getLocationClassSection()).then(res => {
  //   // console.log(res.payload)
  //   setLocationClassData(res.payload)
  //   setSections(res.payload.locationSection)
  //   });
  //   }, [dispatch]);
  useEffect(() => {
    dispatch(getLocationClassSection())
        .then(res => {
            if (res.payload) {
                // console.log(res.payload);
                setLocationClassData(res?.payload);
                setSections(res.payload?.locationSection);
                setSecondSections(res.payload?.classSection); // Assuming you want to use this
            }
        })
        .catch(error => {
            console.error("Error fetching location class section:", error);
        });
  }, [dispatch]);

  // console.log(sections)
  // console.log(secondSections)

  const addSection = () => {
    // Ensure `sections` is an array
    const updatedSections = Array.isArray(sections) ? sections : [];
    
    const newSection = {
        id: Date.now(),
        order: updatedSections.length,
        // panels: [] // Each section starts with an empty list of panels
    };

    setSections([...updatedSections, newSection]);
};

const deleteSectionLocal = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
};

const deleteSecondSectionLocal = (id) => {
    const updatedSections = secondSections.filter((section) => section.id !== id);
    setSecondSections(updatedSections);
};

  const addSecondSection = () => {

    const updatedSecondSections = Array.isArray(secondSections) ? secondSections : [];
    const secSection = {
      id: Date.now(),
      order: updatedSecondSections.length,
      // panels: [] // Each section starts with an empty list of panels
    };
    setSecondSections([...updatedSecondSections, secSection]);
  };

  const deleteSectionHandler = async (sectionId, setStateCallback) => {
  if (!sectionId) {
    toast.error("Section ID is missing. Please try again.");
    return;
  }

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/locationClassSection/${sectionId}`
    );

    setStateCallback(prevSections =>
      prevSections.filter(section => section._id !== sectionId)
    );
    
    toast.success("Section deleted successfully!");
  } catch (error) {
    console.error("Error deleting section:", error.response?.data || error.message);
    toast.error("Failed to delete the section. Please refresh and try again.");
  }
};

  const deleteSection = sectionId => deleteSectionHandler(sectionId, setSections);
  const deleteSecondSection = sectionId => deleteSectionHandler(sectionId, setSecondSections);

  // const deleteSection = async (sectionId) => {
  //   try {
  //     const response = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/locationClassSection/${sectionId}`
  //     );

  //     // console.log(response.data); // Log response to verify success

  //     // Update the state to remove the section from the frontend
  //     setSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  //   } catch (error) {
  //     console.error("Error deleting section:", error.response?.data || error.message);
  //   }
  // };

  const confirmDeleteSec = (id) => {
      confirmAlert({
        title: "Delete This Location",
        message: "Are you sure to do delete this location?",
        buttons: [
          {
            label: "Delete",
            onClick: () => deleteSection(id),
          },
          {
            label: "Cancel",
            // onClick: () => alert("Click No"),
          },
        ],
      });
  };

  // const deleteSecondSection = async (sectionId) => {
  //     // setSecondSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  //   try {
  //     const response = await axios.delete(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/locationClassSection/${sectionId}`
  //     );

  //     // console.log(response.data); // Log response to verify success

  //     // Update the state to remove the section from the frontend
  //     setSecondSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  //   } catch (error) {
  //     console.error("Error deleting section:", error.response?.data || error.message);
  //   }
  // };

  const confirmDeleteSecondSec = (id) => {
      confirmAlert({
        title: "Delete This Class",
        message: "Are you sure to do delete this class?",
        buttons: [
          {
            label: "Delete",
            onClick: () => deleteSecondSection(id),
          },
          {
            label: "Cancel",
            // onClick: () => alert("Click No"),
          },
        ],
      });
  };

  const locationHandler = (e, i) => {
    var locationVal = e.target.value
    var locationX = JSON.parse(JSON.stringify(sections));
    locationX[i].title = locationVal
      // console.log(sections[i])
      setSections(locationX);
  };

  const addressHandler = (e, i) => {
    var addressVal = e.target.value
    var addressX = JSON.parse(JSON.stringify(sections));
    addressX[i].address = addressVal
      // console.log(sections[i])
      setSections(addressX);
  };

  const statusHandler = (e, i) => {
    var statusVal = e.target.value
    var statusX = JSON.parse(JSON.stringify(sections));
    statusX[i].status = statusVal
      // console.log(sections[i])
      setSections(statusX);
  };

  const statusSecondSectionsHandler = (e, i) => {
    var statusVal = e.target.value
    var statusX = JSON.parse(JSON.stringify(secondSections));
    statusX[i].status = statusVal
      // console.log(sections[i])
      setSecondSections(statusX);
  };

  const classHandler = (e, i) => {
    var classVal = e.target.value
    var classX = JSON.parse(JSON.stringify(secondSections));
    classX[i].title = classVal
      // console.log(sections[i])
      setSecondSections(classX);
  };

  const classColorHandler = (e, i) => {
  const color = e.target.value;

  // Create a deep copy of the object to avoid mutation
  const updatedSections = secondSections.map((section, index) => {
    if (index === i) {
      return { ...section, color };  // Create a new object with updated color
    }
    return section;
  });

  setSecondSections(updatedSections); // Update the state with the modified array
};

  const classBookingHandler = (e, i) => {
  const isChecked = e.target.checked;
  
  // Make a shallow copy of the sections array
  const updatedSections = [...secondSections];

  // Explicitly set the value of 'allowMultipleBookings' to true or false
  updatedSections[i].allowMultipleBookings = isChecked ? true : false;

  // Update the state with the modified sections array
  setSecondSections(updatedSections);
};

  // const courseSectionAdd = async (e) => {
  //   e.preventDefault();
  //   var locationClassDataArr = JSON.parse(JSON.stringify(locationClassData));
  //   locationClassDataArr.locationSection = sections
  //   locationClassDataArr.classSection = secondSections
  //   // console.log(90, locationClassDataArr);
  //   await dispatch(addLocationClassSection(locationClassDataArr));
  //   // setSections({locationSection: ''});
  //   // setSecondSections({classSection: ''});
  //   hanldeLocationModalClose();
  //   // forceUpdate();
  // };
	
  const courseSectionAdd = async (e) => {
  e.preventDefault();

  // Ensure sections and secondSections are defined arrays
  // if (!Array.isArray(sections)) {
  //   toast.error("Sections data is invalid or missing.");
  //   return;
  // }
  // if (!Array.isArray(secondSections)) {
  //   toast.error("Class sections data is invalid or missing.");
  //   return;
  // }

  // Validate sections and their panels
  const hasEmptyFields = sections.some(section => {
    if (!section.title || section.title.trim() === "") {
      return true; // Empty section title
    }
    return section.panels?.some(panel => !panel.title || panel.title.trim() === ""); // Empty panel title
  });

  if (hasEmptyFields) {
    toast.error("Please fill in all section and lesson fields before saving.");
    return;
  }

  // Validate secondSections for empty fields (if needed)
  const hasEmptySecondFields = Array.isArray(secondSections) && secondSections.some(section => {
    if (!section?.title || section.title.trim() === "") {
      return true; // Empty class section title
    }
    return Array.isArray(section.panels) && section.panels?.some(panel => !panel?.title || panel.title.trim() === ""); // Empty class panel title
  });

  if (hasEmptySecondFields) {
    toast.error("Please fill in all class section and lesson fields before saving.");
    return;
  }

  // var locationClassDataArr = JSON.parse(JSON.stringify(locationClassData || {}));
  //   locationClassDataArr.locationSection = sections
  //   locationClassDataArr.classSection = secondSections

  let locationClassDataObj = {};
  if (typeof locationClassData === "object" && locationClassData !== null) {
    locationClassDataObj = { ...locationClassData }; // Clone the object
  }

  locationClassDataObj.locationSection = sections;
  locationClassDataObj.classSection = secondSections;

  // Prepare data for submission
  // const locationClassDataArr = {
  //   ...locationClassData,
  //   locationSection: sections,
  //   classSection: secondSections,
  // };

  try {
    let response;
    // Check if locationClassData exists
    if (!locationClassData || typeof locationClassData !== "object") {
      // If no location and class data, call addLocationClassSection
      response = await dispatch(addLocationClassSection(locationClassDataObj));
      // toast.success("Location and class sections added successfully!");
    } else {
      // If data exists, update it
      response = await dispatch(updateLocationClassSection(locationClassDataObj));
      // toast.success("Location and class sections updated successfully!");
    }
    const updatedData = response.payload; // Assuming your Redux action returns the updated data
    setSections(updatedData.locationSection || []);
    setSecondSections(updatedData.classSection || []);
    // Reset sections if required
    // setSections([]);
    // setSecondSections([]);
    // toast.success("Sections added successfully!");

    // Close modal
    hanldeLocationModalClose();
  } catch (error) {
    toast.error("An error occurred while saving the sections.");
    console.error("Error adding sections:", error);
  }
};

const [ipAddress, setIpAddress] = useState(props?.ipAddress || ''); // Default to an empty string if no prop is passed

useEffect(() => {
  fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      setIpAddress(data.ip); // Update the ipAddress with the fetched data
    })
    .catch(error => console.error('Error fetching IP address:', error));
}, []);

useEffect(() => {
  if (sections?.length > 0 && ipAddress && !sections.some(section => section.address)) {
    const updatedSections = sections.map((section, index) => {
      if (index === 0) {
        // Return a new object with the updated address for the first section
        return { ...section, address: ipAddress };
      }
      return section; // Keep other sections unchanged
    });
    setSections(updatedSections);
  }
}, [ipAddress, sections]);

// const handleDragStart = (event, section) => {
//   event.dataTransfer.setData('section', JSON.stringify(section));
// };

// const handleDragOver = (event) => {
//   event.preventDefault();
// };

// const handleDrop = (event, targetIndex) => {
//   const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
//   const newSections = [...sections];
//   const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
//   newSections.splice(currentIndex, 1);
//   newSections.splice(targetIndex, 0, droppedItem);
//   setSections(newSections);
// };

const handleDragStart = (event, section, isSecondSection = false) => {
  // Store the dragged item's data
  const key = isSecondSection ? 'secondSection' : 'section';
  event.dataTransfer.setData(key, JSON.stringify(section));
  // const data = JSON.stringify(section);
  // event.dataTransfer.setData(key, data);
  // console.log(`Data set for ${key}:`, data);
};

const handleDragOver = (event) => {
  // Allow the drop
  event.preventDefault();
};

const handleDrop = (event, targetIndex, isSecondSection = false) => {
  const key = isSecondSection ? 'secondSection' : 'section';
  const data = event.dataTransfer.getData(key);

  if (!data) {
    console.error(`No data found for ${key}`);
    return;
  }

  try {
    const droppedItem = JSON.parse(data);

    if (isSecondSection) {
      const newSections2 = [...secondSections];
      const currentIndex2 = newSections2.findIndex((item) => item.id === droppedItem.id);
      if (currentIndex2 !== -1) {
        newSections2.splice(currentIndex2, 1);
        newSections2.splice(targetIndex, 0, droppedItem);
        setSecondSections(newSections2);
      }
    } else {
      const newSections = [...sections];
      const currentIndex = newSections.findIndex((item) => item.id === droppedItem.id);
      if (currentIndex !== -1) {
        newSections.splice(currentIndex, 1);
        newSections.splice(targetIndex, 0, droppedItem);
        setSections(newSections);
      }
    }
  } catch (error) {
    console.error('Error parsing data during drop:', error);
  }
};

	return (
		<>
    
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="col-md-12">
                              <div className="form-group text-end">
                                  <Link to="/crm/course" className="btn btn-secondary _effect--ripple waves-effect waves-light">New Course</Link>
                                  <Link onClick={handleLocationModalShow} to="" className="btn btn-secondary _effect--ripple waves-effect waves-light mx-1">Locations & Classes</Link>
                              </div>
                          </div>

                          <div className="statbox widget box box-shadow moduleData">
                              <div className="widget-content widget-content-area">
                              {filteredCourses.length === 0 ? (
                                      <p>Course not found...</p>
                                    ):(
                              	<MaterialReactTable 
                              	columns={columns} 
                              	data={filteredCourses} 
                              	onColumnVisibilityChange={setColumnVisibility}
                              	state={{columnVisibility}}
                              	options={{
												          filtering: true // Enable filtering globally
												        }}
												        enableRowSelection={true}
                              	enableRowNumbers 
                              	rowNumberMode="original"
                              	/>
                              )}
                              </div>
                          </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	


        {/*<!-- Modal -->*/}
        <Modal show={ViewLocationModal}
                    onHide={hanldeLocationModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="modal-xl w-100"
                    >
        <div className="form-group text-end">
          <button type="submit" onClick={courseSectionAdd} className="m-1 btn btn-secondary">Save</button>
          <button onClick={hanldeLocationModalClose} className="m-1 btn btn-secondary">Close</button>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="courseCurriculumSec">
              <h4>Locations</h4>
              <div  className="section d-flex align-items-baseline">
               
              <div className="newSecPanel">
              
              <div className="panels">
                  {sections?.map((section, i) => (
                    <div key={section.id || section._id} className="panel d-flex align-items-baseline" draggable
                    onDragStart={(event) => handleDragStart(event, section)}
                    onDragOver={(event) => handleDragOver(event)}
                    onDrop={(event) => handleDrop(event, i)}>
                    <div>
                      <button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
                    </div>
                    <div className="newLessonPanel d-flex align-items-center justify-content-between">
                  <div className="col-10">
                  
                      <form className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="col-12 row">
                        <div className="form-group col-4">
                            <input type="text" className="form-control" placeholder="New Location" defaultValue={sections[i].title} name={`location-${i}`} onChange={(e) => locationHandler(e, i)}/>
                        </div>
                        <div className="form-group col-4">
                            <input type="text" className="form-control" placeholder="IP Address" defaultValue={sections[i].address} name={`address-${i}`} onChange={(e) => addressHandler(e, i)}/>
                        </div>
                        <div className="col-4">
                          <select name={`status-${i}`} value={sections[i]?.status} onChange={(e) => statusHandler(e, i)} className="form-select form-control-sm mb-3">
                              <option>Status</option>
                              <option value="active">Active</option>
                              <option value="in-active">In-active</option>
                            </select>
                        </div>
                      </div>
                        
                              {/*<div className="d-flex">
                                <button type="button" className="btn btn-secondary mx-1">Save</button>
                                <button type="button" className="btn btn-secondary">Cancel</button>
                              </div>*/}
                      </form>
                      </div>
                      <div className="newSecPanelDropDown">
                      {['end'].map(
                        (direction) => (
                          <DropdownButton
                            key={direction}
                            id={`dropdown-button-drop-${direction}`}
                            drop={direction}
                            title={<i className="fa fa-ellipsis-v"></i>}
                          >
                          <li>
                        <span>Set as a public preview</span>
                            </li>
                            <li>
                        <span>Rename Location</span>
                            </li>
                            {/* Conditionally render Remove or Delete Location */}
                            {section._id ? (
                                <li onClick={() => confirmDeleteSec(section._id)}>
                                    <span>Delete Location</span>
                                </li>
                            ) : (
                                <li onClick={() => deleteSectionLocal(section.id)}>
                                    <span>Remove Location</span>
                                </li>
                            )}
                            
                          </DropdownButton>
                        ),
                      )}
                  </div>
                </div>
                    </div>
                  ))}
                </div>
                <div className="newSecPanelBottom">
                  <ul>
                    <li onClick={addSection}>
                      <i className="fa fa-plus-circle"></i>
                      <span>New Location</span>
                    </li>
                    {/*<li>
                      <span>Bulk upload</span>
                    </li>
                    <li>
                      <span>Location summary</span>
                    </li>*/}
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="courseCurriculumSec">
            <h4>Classes</h4>
              <div  className="section d-flex align-items-baseline">
               
              <div className="newSecPanel">
                
              <div className="panels">
                  {secondSections?.map((secondSection, i) => (
                    <div key={secondSection.id} className="panel d-flex align-items-baseline" draggable
                    onDragStart={(event) => handleDragStart(event, secondSection, true)}
                    onDragOver={(event) => handleDragOver(event)}
                    onDrop={(event) => handleDrop(event, i, true)}>
                    <div>
                      <button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
                    </div>
                    <div className="newLessonPanel d-flex align-items-center justify-content-between">
                  <div className="col-10">
                  
                      <form className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="col-12 row">
                        <div className="form-group col-4">
                            <input type="text" className="form-control" placeholder="New Class" defaultValue={secondSections[i].title} name={`class-${i}`} onChange={(e) => classHandler(e, i)}/>
                        </div>
                        <div className="col-4">
                          <select name={`status-${i}`} value={secondSections[i]?.status} onChange={(e) => statusSecondSectionsHandler(e, i)} className="form-select form-control-sm mb-3">
                              <option>Status</option>
                              <option value="active">Active</option>
                              <option value="in-active">In-active</option>
                            </select>
                        </div>
                        <div className="form-group col-1">
                            <input type="color" className="form-control" value={secondSections[i]?.color} placeholder="Color Picker" style={{height:'40px'}} onChange={(e) => classColorHandler(e, i)}/>
                        </div>
                        <div className="col-3 multipleBookingCheck">
                          <input type="checkbox" id={`bookings-${i}`} name={`bookings-${i}`} value={secondSections[i]?.allowMultipleBookings} onChange={(e) => classBookingHandler(e, i)} checked={secondSections[i]?.allowMultipleBookings || false}/>
                          <label htmlFor={`bookings-${i}`}>Allow multiple bookings</label>
                        </div>
                      </div>
                        
                              {/*<div className="d-flex">
                                <button type="button" className="btn btn-secondary mx-1">Save</button>
                                <button type="button" className="btn btn-secondary">Cancel</button>
                              </div>*/}
                      </form>
                      </div>
                      <div className="newSecPanelDropDown">
                      {['end'].map(
                        (direction) => (
                          <DropdownButton
                            key={direction}
                            id={`dropdown-button-drop-${direction}`}
                            drop={direction}
                            title={<i className="fa fa-ellipsis-v"></i>}
                          >
                          <li>
                        <span>Set as a public preview</span>
                            </li>
                            <li>
                        <span>Rename Class</span>
                            </li>
                            {/* Conditionally render Remove or Delete Location */}
                            {secondSection._id ? (
                                <li onClick={() => confirmDeleteSecondSec(secondSection._id)}>
                                    <span>Delete Location</span>
                                </li>
                            ) : (
                                <li onClick={() => deleteSecondSectionLocal(secondSection.id)}>
                                    <span>Remove Location</span>
                                </li>
                            )}
                          </DropdownButton>
                        ),
                      )}
                  </div>
                </div>
                    </div>
                  ))}
                </div>
                <div className="newSecPanelBottom">
                  <ul>
                    <li onClick={addSecondSection}>
                      <i className="fa fa-plus-circle"></i>
                      <span>New Class</span>
                    </li>
                    {/*<li>
                      <span>Bulk upload</span>
                    </li>
                    <li>
                      <span>Class summary</span>
                    </li>*/}
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
          
        <div className="form-group text-end">
          <button type="submit" onClick={courseSectionAdd} className="m-1 btn btn-secondary">Save</button>
          <button onClick={hanldeLocationModalClose} className="m-1 btn btn-secondary">Close</button>
        </div>
      </Modal>
		</>
	)
}

export default AllCourses