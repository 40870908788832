import React,{useState,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import { toast } from "react-toastify";
import Sidebar from '../components/Sidebar';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import Footer from '../components/Footer';
import DropdownButton from 'react-bootstrap/DropdownButton';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CourseDocsModal from "./CourseDocsModal";
import {
  selectCourse,
  getCourse,
  updateCourseSection,
}   from "../../redux/crm-features/course/courseSlice";
import LearningObjectivesModal from "./LearningObjectivesModal";
import CourseViewDocsModal from "./CourseViewDocsModal";

const initialState ={
	courseSection : '',
}

const CourseFromScratch = () => {

	useRedirectLoggedOutUser("/crm/login");

	const { id } = useParams();
	const [courseData, setCourseData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {courseSection} = courseData;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [sections, setSections] = useState([]);
	const { course } = useSelector(
      (state) => state.course
  	);

  	useEffect(() => {
      dispatch(getCourse(id)).then(res => {
		// console.log(res.payload)
		setCourseData(res.payload)
		setSections(res.payload.courseSection)
	  });
  	}, [dispatch, id]);
  	// console.log(courseData)
  	console.log(sections)

	useEffect(() => {
	    // Initialize with one section when the component mounts
	    if(sections.length===0) {
			addSection();
		} else {

		}
	}, []);

	const addSection = () => {
	    const newSection = {
	    	id: Date.now(),
	      order: sections.length,
	      panels: [] // Each section starts with an empty list of panels
	    };
	    setSections([...sections, newSection]);
	};

	const sectionHandler = (e, i) => {
		var vx = e.target.value
		var sectionX = JSON.parse(JSON.stringify(sections));

		sectionX[i].title = vx
	    // console.log(sectionX[i])
	    setSections(sectionX);
	};

	const lessonHandler = (e, i, ix) => {
		var vx = e.target.value
		var sectionX = JSON.parse(JSON.stringify(sections));
		sectionX[i].panels[ix].title = vx
	    // console.log(sections[i])
	    setSections(sectionX);
	};

	const deleteCourseItemHandler = async (sectionId, lessonId, setStateCallback) => {
  try {
    const url = lessonId
      ? `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/deleteCourseItem/${sectionId}/${lessonId}`
      : `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/deleteCourseItem/${sectionId}`;

    // Make the API call to delete the course item
    const response = await axios.delete(url);

    // Log the response for debugging
    // console.log("Delete Course Item Response:", response.data);

    // Update the state
    setStateCallback(prevSections => {
      if (lessonId) {
        // If deleting a lesson, update the corresponding section's panels
        return prevSections.map(section => {
          if (section._id === sectionId) {
            return {
              ...section,
              panels: section.panels.filter(panel => panel._id !== lessonId),
            };
          }
          return section;
        });
      } else {
        // If deleting a section, remove it from the state
        return prevSections.filter(section => section._id !== sectionId);
      }
    });
  } catch (error) {
    console.error(
      "Error deleting course item:",
      error.response?.data || error.message
    );
    // Optionally display an error message to the user
  }
};

const confirmDeleteCourseItem = (sectionId, lessonId, setStateCallback) => {
  const itemType = lessonId ? "Lesson" : "Section";
  confirmAlert({
    title: `Delete This ${itemType}`,
    message: `Are you sure you want to delete this ${itemType.toLowerCase()}?`,
    buttons: [
      {
        label: "Delete",
        onClick: () =>
          deleteCourseItemHandler(sectionId, lessonId, setStateCallback),
      },
      {
        label: "Cancel",
        // No action needed for cancel button
      },
    ],
  });
};

	// const deleteSectionHandler = async (sectionId, setStateCallback) => {
	//   try {
	//     // Make the API call to delete the section
	//     const response = await axios.delete(
	//       `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/deleteCourseSection/${sectionId}`
	//     );

	//     // Log the response for debugging (optional)
	//     console.log(response.data);

	//     // Update the respective state
	//     setStateCallback(prevSections =>
	//       prevSections.filter(section => section._id !== sectionId)
	//     );
	//   } catch (error) {
	//     console.error(
	//       "Error deleting section:",
	//       error.response?.data || error.message
	//     );
	//     // Optionally, display an error message to the user
	//   }
	// };

	// const deleteSection = sectionId => deleteSectionHandler(sectionId, setSections);

	// const deleteSection = (sectionId) => {
  //   	setSections(prevSections => prevSections.filter(section => section.order !== sectionId));
  // 	};

  // 	const confirmDeleteSec = (id) => {
	//     confirmAlert({
	//       title: "Delete This Section",
	//       message: "Are you sure to do delete this section?",
	//       buttons: [
	//         {
	//           label: "Delete",
	//           onClick: () => deleteSection(id),
	//         },
	//         {
	//           label: "Cancel",
	//           // onClick: () => alert("Click No"),
	//         },
	//       ],
	//     });
	// };

// 	const deleteLessonHandler = async (sectionId, lessonId, setStateCallback) => {
//   try {
//     // Make the API call to delete the lesson
//     const response = await axios.delete(
//       `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/deleteCourseLesson/${sectionId}/${lessonId}`
//     );

//     // Log the response for debugging
//     console.log("Delete Lesson Response:", response.data);

//     // Update the state by removing the lesson
//     setStateCallback(prevSections =>
//       prevSections.map(section => {
//         if (section._id === sectionId) {
//           return {
//             ...section,
//             panels: section.panels.filter(panel => panel._id !== lessonId),
//           };
//         }
//         return section;
//       })
//     );
//   } catch (error) {
//     console.error(
//       "Error deleting lesson:",
//       error.response?.data || error.message
//     );
//     // Optionally display an error message to the user
//   }
// };

// const deleteLesson = (sectionId, lessonId) => {
//   setSections(prevSections =>
//     prevSections.map(section => {
//       if (section.order === sectionId) {
//         return {
//           ...section,
//           panels: section.panels.filter(panel => panel.order !== lessonId),
//         };
//       }
//       return section;
//     })
//   );
// };

// const confirmDeleteLesson = (sectionId, lessonId, setStateCallback) => {
//   confirmAlert({
//     title: "Delete This Lesson",
//     message: "Are you sure you want to delete this lesson?",
//     buttons: [
//       {
//         label: "Delete",
//         onClick: () => deleteLessonHandler(sectionId, lessonId, setStateCallback),
//       },
//       {
//         label: "Cancel",
//         // No action needed for cancel button
//       },
//     ],
//   });
// };

	const addPanel = (sectionId, panelContent) => {
	    setSections(prevSections => {
	      return prevSections.map(section => {
	        if (section.id === sectionId) {
	          // Add a new panel to the specified section
	          return {
	            ...section,
	            panels: [...section.panels, { id: Date.now(), order: section.panels.length, content: panelContent }]
	          };
	        }
	        return section;
	      });
	    });
	};

const removeSection = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
};

const removePanel = (sectionId, panelId) => {
    setSections(prevSections =>
        prevSections.map(section => {
            if (section.id === sectionId) {
                return {
                    ...section,
                    panels: section.panels.filter(panel => panel.id !== panelId)
                };
            }
            return section;
        })
    );
};


	const courseSectionAdd = async (e) => {
		e.preventDefault();

		// Validate sections and their panels
  const hasEmptyFields = sections.some(section => {
    if (!section.title || section.title.trim() === "") {
      return true; // Empty section title
    }
    return section.panels.some(panel => !panel.title || panel.title.trim() === ""); // Empty panel title
  });

  if (hasEmptyFields) {
    toast.error("Please fill in all section and lesson fields before saving.");
    return;
  }

  const sanitizedSections = sections.map(({ expandedIcon, ...rest }) => rest);

		var courseDataArr = JSON.parse(JSON.stringify(courseData));
		// courseDataArr.courseSection = sections
		courseDataArr.courseSection = sanitizedSections;
		// console.log(90, courseDataArr);
		// await dispatch(updateCourseSection(courseDataArr));
		
		// forceUpdate();
		try {
    // Dispatch the update action to save the course data
    const response = await dispatch(updateCourseSection(courseDataArr));
    
    // Optionally update the state with the response to reflect the new course data
    if (response && response.payload) {
      setSections(response.payload.courseSection); // Update sections state immediately
      setCourseData(response.payload); // Update course data state immediately

      // Log to console to verify
      // console.log("Updated Course Data:", response.payload);
      // console.log("Updated Sections:", response.payload.courseSection);
    }

    // Force re-render (if needed)
    forceUpdate();
  } catch (error) {
    toast.error("An error occurred while saving the course sections.");
  }
	};

	const [cancelState, setCancelState] = useState({}); // Tracks cancel state for each section
const [lessonState, setLessonState] = useState({}); // Tracks cancel state for each lesson

const handleCancel = (sectionId) => {
    setCancelState((prevState) => ({
        ...prevState,
        [sectionId]: false,
    }));
};

const handleCancelLesson = (sectionId, panelId) => {
    setLessonState((prevState) => ({
        ...prevState,
        [`${sectionId}-${panelId}`]: false,
    }));
};

const toggleRenameSection = (sectionId) => {
    setCancelState((prevState) => ({
        ...prevState,
        [sectionId]: !prevState[sectionId],
    }));
};

const toggleRenameLesson = (sectionId, panelId) => {
    setLessonState((prevState) => ({
        ...prevState,
        [`${sectionId}-${panelId}`]: !prevState[`${sectionId}-${panelId}`],
    }));
};

  	const handleDragStart = (event, section) => {
  event.dataTransfer.setData('section', JSON.stringify(section));
};

const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
  const newSections = [...sections];
  const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
  newSections.splice(currentIndex, 1);
  newSections.splice(targetIndex, 0, droppedItem);
  setSections(newSections);
};

	const [isModalVisible, setModalVisibility] = useState(false);

	const showLearningObjectivesModal = (section) => setModalVisibility(section);
	const hideLearningObjectivesModal = () => setModalVisibility(false);

	const [showModal, setShowModal] = useState(false);

	// Function to open the modal
	const handleOpenModal = (section) => {
  	setShowModal(section); // Show the modal
	};

	// Function to close the modal
	const handleCloseModal = () => setShowModal(false);

	const [expandedIcon, setExpandedIcon] = useState(false); // Manage expanded state

const handleToggleIcon = (id) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id
          ? { ...section, expandedIcon: !section.expandedIcon }
          : section
      )
    );
  };

  const [viewDocModal, SetViewDocModalShow] = useState(false)
  const handleViewDocModalShow = (section) => { SetViewDocModalShow(section) }
  const hanldeViewDocModalClose = () => { SetViewDocModalShow(false) }


	return (
		<>
		
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseCurriculumHeading">
		                    		<span><Link to="/crm/all-courses">course</Link> / <Link to={`/crm/course-detail/${course?._id}`}>{course && (<>{course.courseTitle}</>) } </Link></span>
		                    		<div className="d-flex align-items-center justify-content-between">
		                    			<h3>Curriculum</h3>
		                    			<div>
		                    			<button type="button" className="btn btn-secondary mx-1">Preview</button>
		                    			<button type="button" className="btn btn-secondary" onClick={courseSectionAdd}>Save</button>
		                    			</div>
		                    		</div>
		                    	</div>

		                    	<div className="col-12">
		                    		<Link to="" className="btn btn-secondary _effect--ripple waves-effect waves-light">Bulk edit</Link>
		                    		<Link to={`/crm/course/curriculum-import/${course?._id}`} className="btn btn-secondary _effect--ripple waves-effect waves-light mx-1">Import content</Link>		                    		
		                    	</div>

		                    	<div className="courseCurriculumSec row">

		                    		{/*-----------Sec Panel------*/}
		                    	{sections?.map((section, i) => (
									<div key={section.id || section._id} className="section d-flex align-items-baseline accordion-item" draggable
										onDragStart={(event) => handleDragStart(event, section)}
										onDragOver={(event) => handleDragOver(event)}
										onDrop={(event) => handleDrop(event, i)}>
        								<div>
		          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
							          	</div>
								        <div className="newSecPanel">
								        	<div className="newSecPanelHead d-flex align-items-center justify-content-between">
								        		<div className="col-8">
								        		{
								        			cancelState[section.id] !== false ? 
								        			<form className="d-flex align-items-center justify-content-between">
														<div className="form-group w-100">
															<span className={sections[i]?.isLocationRestricted === true ? 'text-bg-danger' : ''}>{sections[i]?.isLocationRestricted === true ? 'Location restricted' : ''}</span>
															<input type="text" className="form-control" defaultValue={sections[i].title} name={`section-${i}`} placeholder="New section / Client" onChange={(e) => sectionHandler(e, i)}/>
									                    </div>
									                    <div className="d-flex accordion-header" id={`heading-${i}`}>
									                    {section.panels && section.panels.length > 0 && (
									                    	<button onClick={() => handleToggleIcon(section.id)} aria-controls={`collapse-${i}`} aria-expanded={section.expandedIcon ? "true" : "false"} data-bs-target={`#collapse-${i}`} type="button"
									                    	 data-bs-toggle="collapse" className="btn btn-secondary mx-1"><i className={`fa ${section.expandedIcon ? "fa-minus-circle" : "fa-plus-circle"}`}></i>
									                    	 </button>
									                    )}
															<button className={sections[i]?.isPublished === true ? 'btn btn-outline-success' : 'btn btn-outline-info'}>{sections[i]?.isPublished === true ? 'Published' : 'Unpublished'}</button>

															{/*<button onClick={() => handleCancel(section.id)} type="button" className="btn btn-secondary mx-1">Cancel</button>*/}
									                    </div>
								        			</form>
								        			:
								        			<h4>New Section</h4>
								        		}
								                </div>
												<div className="newSecPanelDropDown">{['end'].map((direction) => (
													<DropdownButton key={direction}
																	id={`dropdown-button-drop-${direction}`}
																	drop={direction}
																	title={<i className="fa fa-ellipsis-v"></i>}>
														<li onClick={() => {handleViewDocModalShow(section)}}><span>View Uploaded Docs</span></li>
														<span><div className="dropdown-divider"></div></span>
														<li className={sections[i]?.isPublished === true ? 'text-bg-success' : ''}>
															<span>Published</span>
														</li>
														<li className={sections[i]?.isLocationRestricted === true ? 'text-bg-danger' : ''}>
															<span>Location Restricted</span>
														</li>
														{/*<li onClick={() => toggleRenameSection(section.id)}><span>Rename Section</span></li>*/}
														<li onClick={() => handleOpenModal(section)}><span>Upload Docs</span></li>
														<li onClick={() => showLearningObjectivesModal(section)}><span>Set Learning Objectives</span></li>
														<span><div className="dropdown-divider"></div></span>
														{section._id ? (
															<li onClick={() => confirmDeleteCourseItem(section._id, null, setSections)}>
																<span>Delete Section</span></li>) : (
															<li onClick={() => removeSection(section.id)}><span>Remove Section</span>
															</li>)}
													</DropdownButton>),)}
												</div>
								        	</div>
								        {/****************panel**********************/}
								        <div
								        id={`collapse-${i}`}
								        className={`accordion-collapse collapse ${section.expanded ? "show" : ""}`} // Added 'show' to keep panels open by default
								        aria-labelledby={`heading-${i}`}
								      >
								        <div className="accordion-body">
								    		<div className="panels">
								            {section?.panels.map((panel, ix) => {
								            	const videoCount = panel?.media?.filter(
													      (item) => item.type === "video" || item.type === "VIDEO"
													    ).length || 0;
													    const imageCount = panel?.media?.filter(
													      (item) => item.type === "image" || item.type === "IMAGE"
													    ).length || 0;
													    const fileCount = panel?.media?.filter(
													      (item) => item.type === "pdf" || item.type === "PDF"
													    ).length || 0;

													    const hasMedia = videoCount > 0 || imageCount > 0 || fileCount > 0;
													    return (
								              <div key={panel.id || panel._id} className="panel d-flex align-items-center">
								                {/*{panel.content}*/}
								              <div>
				          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
									          	</div>
								              <div className="newLessonPanel d-flex align-items-center justify-content-between">
										    		<div className="col-8">
										    		{
									        			lessonState[`${section.id}-${panel.id}`] !== false ? 
									        			<form className="d-flex align-items-center justify-content-between">
									        				<div className="form-group w-100">
										                        <input type="text" className="form-control" defaultValue={sections[i].panels[ix].title} name={`lesson-${i}-${ix}`} placeholder="New lesson" onChange={(e) => lessonHandler(e, i, ix)}/>
										                        {hasMedia ? (
													                    <small>
													                      {videoCount > 0 && `${videoCount} ${videoCount === 1 ? "Video" : "Videos"}`}
													                      {imageCount > 0 && `${imageCount} ${imageCount === 1 ? "Image" : "Images"}`}
													                      {fileCount > 0 && `${fileCount} ${fileCount === 1 ? "File" : "Files"}`}
													                    </small>
													                  ) : (
													                    <small>No media available</small>
													                  )}
										                    </div>
										                    <div className="d-flex">
										                    	{/*<button type="button" className="btn btn-secondary mx-1">Save</button>*/}
									                    		{/*<button onClick={() => handleCancelLesson(section.id, panel.id)} type="button" className="btn btn-secondary mx-1">Cancel</button>*/}
										                    </div>
									        			</form>
									        			:
									        			<h6><Link to={`/crm/course-content/${course?._id}/${panel._id}`}>New Lesson</Link></h6>
									        		}
										    			{/*<form className="d-flex align-items-center justify-content-between">
										    				<div className="form-group w-100">
										                        <input type="text" className="form-control" placeholder="New lesson"/>
										                    </div>
										                    <div className="d-flex">
										                    	<button type="submit" className="btn btn-secondary mx-1">Save</button>
										                		<button type="button" className="btn btn-secondary">Cancel</button>
										                    </div>
										    			</form>*/}
										            </div>
										            <div className="newSecPanelDropDown">
												        {['end'].map(
												          (direction) => (
												            <DropdownButton
												              key={direction}
												              id={`dropdown-button-drop-${direction}`}
												              drop={direction}
												              title={<i className="fa fa-ellipsis-v"></i>}
												            >
												            <li>
															    <span>Set as a public preview</span>
										              		</li>
										              		<li>
															    <Link to={`/crm/course-content/${course?._id}/${panel._id}`}><span>Add Learning Material</span></Link>
										              		</li>
										              		<li onClick={() => toggleRenameLesson(section.id, panel.id)}>
															    <span>Rename Lesson</span>
										              		</li>
																<span><div className="dropdown-divider"></div></span>
										              		{section._id && panel._id ? (
					                                <li onClick={() => confirmDeleteCourseItem(section._id, panel._id, setSections)}>
					                                    <span>Delete Lesson</span>
					                                </li>
					                            ) : (
					                                <li onClick={() => removePanel(section.id, panel.id)}>
					                                    <span>Remove Lesson</span>
					                                </li>
					                            )}
												              
												            </DropdownButton>
												          ),
												        )}
												    </div>
										    	</div>
								              </div>
								              );
								            })}
								          </div>
								          </div>
    											</div>
								          {/****************panel**********************/}
								        	<div className="newSecPanelBottom">
								        		<ul>
								        			<li onClick={() => addPanel(section.id, `Panel ${section.panels.length}`)}>
								        				<i className="fa fa-plus-circle"></i>
								        				<span>New lesson</span>
								        			</li>
								        			{/*<li>
								        				<span>Bulk upload</span>
								        			</li>
								        			<li>
								        				<span>Section summary</span>
								        			</li>*/}
								        		</ul>
								        	</div>
								        </div>
								        </div>
								      ))}
			                        {/*-----------Sec Panel------*/}

			                        <div className="addPanelSec" onClick={addSection}>
			                        	<i className="fa fa-plus-circle"></i>
			                        	<span>New section</span>
			                        </div>

		                    	</div>
		                        
		                    </div>

		                </div>

		            </div>

		            <CourseDocsModal
									course={course}
									section={showModal}
									show={Boolean(showModal)}
									handleClose={handleCloseModal}
								/>

								<LearningObjectivesModal
									course={course}
									section={isModalVisible}
									show={Boolean(isModalVisible)}
									handleClose={hideLearningObjectivesModal}
								/>

								<CourseViewDocsModal
									course={course}
									section={viewDocModal}
									show={Boolean(viewDocModal)}
									handleClose={hanldeViewDocModalClose}
								/>


		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

		   
		</>
	)
}

export default CourseFromScratch