import React,{useState,useEffect,useReducer} from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Table } from "react-bootstrap";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getCourse,
}   from "../../redux/crm-features/course/courseSlice";

const initialState ={
    trainingSequence : '',
}

const TrainingSequenceModal = ({ show, handleClose }) => {
    const { id } = useParams();
    // const [courseData, setCourseData] = useState(initialState);
    // const {trainingSequence} = courseData;
    const dispatch = useDispatch();

    const [courseData, setCourseData] = useState({ courseSection: [] });

    const { course } = useSelector(
      (state) => state.course
    );

    // console.log(course.courseSection[0]._id);

    // const [sections, setSections] = useState([]);

    useEffect(() => {
    dispatch(getCourse(id)).then((res) => {
      if (res?.payload) {
        setCourseData((prevData) => ({
          ...prevData,
          courseSection: res.payload.courseSection || [], // Ensure courseSection is always an array
        }));
      }
    });
  }, [dispatch, id]);

    // console.log(course);

    const sectionHandler = (e, sectionIndex, field) => {
  const { name, value } = e.target;

  // Create a new copy of courseData to ensure immutability
  const updatedCourseSection = courseData.courseSection.map((section, sIndex) => {
    if (sIndex === sectionIndex) {
      // Ensure the trainingSequence array exists, and if not, create it with an empty object
      const updatedTrainingSequence = section.trainingSequence[0]
        ? { ...section.trainingSequence[0], [name]: value }
        : { [name]: value }; // If trainingSequence is empty, create a new object with the field update

      // Return the updated section with the new training sequence
      return { ...section, trainingSequence: [updatedTrainingSequence] }; // Wrap it back in an array
    }
    return section; // Return the unchanged section
  });

  // Update the course data state with the new course section data
  setCourseData({ ...courseData, courseSection: updatedCourseSection });
};



    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("Updated sections:", sections);
        // const trainingSequence = course.courseSection.map((section, i) => ({
        //     courseSectionId: section._id,
        //     hours: courseData.trainingSequence[i]?.hours || 0,
        //     industry: courseData.trainingSequence[i]?.industry || '',
        //     software: courseData.trainingSequence[i]?.software || '',
        //     task: courseData.trainingSequence[i]?.task || '',
        // }));
        
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/updateTrainingSequence/${id}`,
                {
                    // courseId: course._id,
                    courseSection: courseData.courseSection
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            console.log("Response data:", response.data);
            toast.success('Training sequence updated successfully');
            handleClose(); // Close the modal after a successful update
        } catch (error) {
            console.error('Error updating training sequence:', error);
            toast.error('Failed to update training sequence');
        }
    };

    // console.log(courseData);    
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="textImageModal modal-xl w-100"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Set Training Sequence for <span className="text-decoration-underline">{course?.courseTitle}</span></h4>
                </Modal.Title>
                <span onClick={handleClose}>
                    <i className="fa fa-times"></i>
                </span>
            </Modal.Header>
            <Modal.Body>
               
                    <Table striped bordered hover>
                        <thead className="fw-bold">
                        <tr>
                            <th>Section Title</th>
                            <th>Industry</th>
                            <th>Task</th>
                            <th>Software</th>
                            <th>Hours</th>
                        </tr>
                        </thead>
                        <tbody>
                        {/*{courseData.courseSection.map((section, sectionIndex) => (
                            <React.Fragment key={section._id}>
                             
                              {section.trainingSequence.map((seq, trainingIndex) => (
                                <tr key={seq._id || trainingIndex}>
                                  <td>{section.title}</td>
                                  <td>
                                    <input
                                      name="industry"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Industry"
                                      value={seq.industry || ''}
                                      onChange={(e) => sectionHandler(e, sectionIndex, trainingIndex)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name="task"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Task"
                                      value={seq.task || ''}
                                      onChange={(e) => sectionHandler(e, sectionIndex, trainingIndex)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name="software"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Software"
                                      value={seq.software || ''}
                                      onChange={(e) => sectionHandler(e, sectionIndex, trainingIndex)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name="hours"
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter Hours"
                                      value={seq.hours || ''}
                                      onChange={(e) => sectionHandler(e, sectionIndex, trainingIndex)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}*/}
                        {courseData.courseSection.map((section, sectionIndex) => (
  <React.Fragment key={section._id}>
    <tr key={section._id}>
      <td>{section.title}</td>
      <td>
        <input
          name="industry"
          type="text"
          className="form-control"
          placeholder="Enter Industry"
          value={section.trainingSequence[0]?.industry || ''} // Accessing the first item in trainingSequence
          onChange={(e) => sectionHandler(e, sectionIndex, 'industry')}
        />
      </td>
      <td>
        <input
          name="task"
          type="text"
          className="form-control"
          placeholder="Enter Task"
          value={section.trainingSequence[0]?.task || ''} // Accessing the first item in trainingSequence
          onChange={(e) => sectionHandler(e, sectionIndex, 'task')}
        />
      </td>
      <td>
        <input
          name="software"
          type="text"
          className="form-control"
          placeholder="Enter Software"
          value={section.trainingSequence[0]?.software || ''} // Accessing the first item in trainingSequence
          onChange={(e) => sectionHandler(e, sectionIndex, 'software')}
        />
      </td>
      <td>
        <input
          name="hours"
          type="number"
          className="form-control"
          placeholder="Enter Hours"
          value={section.trainingSequence[0]?.hours || ''} // Accessing the first item in trainingSequence
          onChange={(e) => sectionHandler(e, sectionIndex, 'hours')}
        />
      </td>
    </tr>
  </React.Fragment>
))}

                        </tbody>
                    </Table>
                
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group text-end">
                    <button onClick={handleSubmit} type="submit" className="m-1 btn btn-secondary">
                        Update
                    </button>
                    <button onClick={handleClose} className="m-1 btn btn-secondary">
                        Close
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default TrainingSequenceModal;
