import React,{useState,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  upgradeCourse,getLocationClassSection
}   from "../../redux/crm-features/course/courseSlice";
import axios from "axios";
import Select from 'react-select';
// const classes = [
//     { label: "Demo1", value: "Demo1" },
//     { label: "Demo2", value: "Demo2" },
//     { label: "Demo3", value: "Demo3" },
//     { label: "Demo4", value: "Demo4" }
//   ];

// const locations = [
//     { label: "Demo1", value: "Demo1" },
//     { label: "Demo2", value: "Demo2" },
//     { label: "Demo3", value: "Demo3" },
//     { label: "Demo4", value: "Demo4" }
//   ];

const CourseFormModal = (props) => {

  //   const { locationClasses } = useSelector(
  //     (state) => state.course
  // );

    const [id, setId] = useState(props?._id);
    const [courseLevel, setCourseLevel] = useState(props?.courseLevel);
    const [courseType, setCourseType] = useState(props?.courseType);
    const [courseAccess, setCourseAccess] = useState(props?.courseAccess);
    // const [courseIpAddress, setCourseIpAddress] = useState(props?.courseIpAddress);
    // const [courseType, setCourseType] = useState(props?.courseType);
    const [courseInstructor, setCourseInstructor] = useState(props?.courseInstructor);
    const [courseDuration, setCourseDuration] = useState(props?.courseDuration);
    const [courseFee, setCourseFee] = useState(props?.courseFee);
    const [courseDiscount, setCourseDiscount] = useState(props?.courseDiscount);
    const [courseTotalHours, setCourseTotalHours] = useState(props?.courseTotalHours);
    const [courseClasses, setCourseClasses] = useState(props?.courseClasses);
    const [courseLocation, setCourseLocation] = useState(props?.courseLocation);
    const dispatch = useDispatch();
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	  const [courseFormModal, setCourseFormModalShow] = useState(false)
    const handleCourseFormModalShow = () => { setCourseFormModalShow(true) }
    const handleCourseFormModalClose = () => { setCourseFormModalShow(false) }

    const [value, setvalue] = useState("");
    // const handleOnchange = (val) => setCourseClasses(val);

    // console.log(courseClasses)
    // console.log(courseLocation)

  	const handleSubmit = async (e) => {
	    e.preventDefault();
	    
      if (!courseFee) {
      toast.error("Please enter a course fee");
      return;
    }

    const courseSetting = [
    {
      courseLevel: courseLevel,
      courseDiscount: courseDiscount,
      courseDuration: courseDuration,
      courseAccess: courseAccess,
      courseTotalHours: courseTotalHours,
      courseClasses: courseClasses,
      courseLocation: courseLocation,
      courseFee: courseFee,
      courseType: courseType,
    },
  ];

    const courseData = {
      id: id,
      courseSetting,
      // courseLevel: courseLevel,
      // courseAccess: courseAccess,
      // courseIpAddress: courseIpAddress,
      // courseType: courseType,
      // courseInstructor: courseInstructor,
      // courseDuration: courseDuration,
      // courseFee: courseFee,
      // courseDiscount: courseDiscount,
      // courseTotalHours: courseTotalHours,
      // courseClasses: courseClasses,
      // courseLocation: courseLocation
    };
    console.log(courseData)
    try {
      await dispatch(upgradeCourse(courseData));
      forceUpdate();
      setCourseFormModalShow(false);
    } catch (error) {
      // console.error("Error updating course:", error);
      toast.error("Error updating course. Please try again later.");
    }
	};

//     useEffect(() => {
//           dispatch(getLocationClassSection());
//     }, [dispatch]);

//     if (locationClasses?.classSection) {
//   console.log(locationClasses.classSection);
// } else {
//   console.log("classSection is undefined");
// }

  // const [courseLevel, setCourseLevel] = useState('');
  // const [showField, setShowField] = useState(false);

  // const handleSelectChange = (event) => {
  //   const selectedOption = event.target.value;
  //   setCourseLevel(selectedOption);

  //   // If 'Online' is selected, set showField to true
  //   setShowField(selectedOption === 'center');
  // };

    const [showField, setShowField] = useState(courseAccess === "Center");

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setCourseAccess(selectedOption);

    // Show or hide the location field based on the selected option
    setShowField(selectedOption === "Center");
  };

  // const [ipAddress, setIpAddress] = useState('');

  // useEffect(() => {
  //   fetch('https://api.ipify.org?format=json')
  //     .then(response => response.json())
  //     .then(data => setCourseIpAddress(data.ip))
  //     .catch(error => console.error('Error fetching IP address:', error));
  // }, []);

  const [classSectionOptions, setClassSectionOptions] = useState([]);
  const [locationSectionOptions, setLocationSectionOptions] = useState([]);

  useEffect(() => {
    const fetchLocationClasses = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/course/crm/getLocationClassSection`);
        // console.log("Fetched Data:", data);
        const classSectionMap = data.classSection.filter(cls => cls.title && cls._id).map(cls => ({
            label: cls.title, // Display title in the dropdown
            value: cls._id,    // Unique identifier for selection
          })
        );
        const locationSectionMap = data.locationSection.filter(loc => loc.title && loc._id).map(loc => ({
            label: loc.title, // Display title in the dropdown
            value: loc._id,    // Unique identifier for selection
          })
        );
        // console.log("Class Section Options:", classSectionMap);
        // console.log("Location Section Options:", locationSectionMap);
        setClassSectionOptions(classSectionMap);
        setLocationSectionOptions(locationSectionMap);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchLocationClasses();
  }, []);

  // console.log(classSectionOptions);
  // console.log(locationSectionOptions);

    const handleOnchangeLocation = (selectedOptions) => {
      // Ensure `selectedOptions` is treated as an array of selected objects
      const formattedLocations = selectedOptions.map((option) => ({
        _id: option.value, // Assuming `option.value` is the _id
      }));

      setCourseLocation(formattedLocations); // Set the selected locations with _id
    };

    const handleOnchange = (selectedOptions) => {
      // Ensure `selectedOptions` is treated as an array
      const formattedClasses = selectedOptions.map((option) => ({
        _id: option.value, // Ensure each option is treated as an ObjectId
      }));

      setCourseClasses(formattedClasses); // This will be an array of { _id: ObjectId }
    };

    // const handleOnchange = (selectedOptions) => {
    //   // Ensure `selectedOptions` is treated as an array
    //   let optionsArray = Array.isArray(selectedOptions)
    //     ? selectedOptions
    //     : selectedOptions.split(",").map((opt) => opt.trim()); // Split comma-separated string if necessary

    //   const formattedClasses = optionsArray.map((option) => ({
    //     _id: option, // Ensure each option is treated as an ObjectId
    //   }));

    //   setCourseClasses(formattedClasses); // This will be an array of { _id: ObjectId }
    // };

//   const items = [
//     { label: 'Item 1', value: '1' },
//     { label: 'Item 2', value: '2' },
//     { label: 'Item 3', value: '3' },
//     { label: 'Item 4', value: '4' },
//     { label: 'Item 5', value: '5' },
//     // Add more items as needed
//   ];
//   const item = [
//     { label: 'Item 1', value: '1' },
//     { label: 'Item 2', value: '2' },
    
//     // Add more items as needed
//   ];
// const [selectedItems, setSelectedItems] = useState(item);

//   const handleChange = (selectedOptions) => {
//     setSelectedItems(selectedOptions);
//   };

	return (
		<>
		<span onClick={handleCourseFormModalShow}>Course Settings</span>
		
			{/*<!-- Modal -->*/}
        <Modal show={courseFormModal}
                    onHide={handleCourseFormModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Course Settings</h4>
                    </Modal.Title>
                    <span onClick={handleCourseFormModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
            <Modal.Body>
                <div className="row">
                   <div className="col-md-4">
                        <label>Course Level</label>
                        <select
                            name="courseLevel"
                            className="form-select form-control-sm mb-3"
                            value={courseLevel}
                            onChange={(e) => setCourseLevel(e.target.value)}
                        >
                            <option>Course Level</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                        </select>
                   </div>
                    <div className="col-md-4">
                        <label>Course Type</label>
                        <select
                            name="courseType"
                            className="form-select form-control-sm mb-3"
                            value={courseType}
                            onChange={(e) => setCourseType(e.target.value)}
                        >
                            <option>Course Type</option>
                            <option value="Training">Training</option>
                            <option value="Course">Course</option>
                        </select>
                    </div>
                   <div className="col-md-4">
                        <label>Course Access</label>
                        <select
                            name="courseAccess"
                            className="form-select form-control-sm mb-3"
                            value={courseAccess}
                            onChange={(e) => {
                                handleSelectChange(e);
                                setCourseAccess(e.target.value);
                            }}
                        >
                            <option>Course Access</option>
                            <option value="Online">Online</option>
                            <option value="Center">Center</option>
                        </select>
                   </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <label>Course Duration(In Months)</label>
                        <input
                            type="number"
                            name="courseDuration"
                            className="form-control mb-3"
                            placeholder="Course Duration (in Months)"
                            value={courseDuration}
                            onChange={(e) => setCourseDuration(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Course Fee</label>
                        <input
                            type="number"
                            className="form-control mb-3"
                            placeholder="Course Fee"
                            name="courseFee"
                            value={courseFee}
                            onChange={(e) => setCourseFee(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label>Course Discount(%)</label>
                        <input
                            type="number"
                            name="courseDiscount"
                            className="form-control mb-3"
                            placeholder="Discount Percentage"
                            value={courseDiscount}
                            onChange={(e) => setCourseDiscount(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <label>Course Learning Hours</label>
                        <input
                            type="number"
                            name="courseTotalHours"
                            className="form-control mb-3"
                            placeholder="Total Learning Hours"
                            value={courseTotalHours}
                            onChange={(e) => setCourseTotalHours(e.target.value)}
                        />
                    </div>
                </div>
                {showField && (
                    <>
                        {/*<div className="col-md-12">
                        <div className="form-group">
                            <label>IP Address</label>
                            <input
                                type="text"
                                className="form-control mb-3"
                                name="courseIpAddress"
                                placeholder="IP Address"
                                value={courseIpAddress}
                                onChange={(e) => setCourseIpAddress(e.target.value)}
                            />
                        </div>
                    </div>*/}
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Course Location</label>
                                <Select
                                    isMulti
                                    className="multi-select w-100 mb-3"
                                    value={(Array.isArray(courseLocation) ? courseLocation : []).map(location => {
                                        // If location has _id, find the corresponding title
                                        const locationTitle = location._id
                                            ? locationSectionOptions.find(option => option.value === location._id)?.label
                                            : locationSectionOptions.find(option => option.value === location)?.label;

                                        return { label: locationTitle, value: location._id || location }; // Return object with label and value
                                    })}
                                    options={locationSectionOptions.filter(
                                        (option) => !(Array.isArray(courseLocation) && courseLocation.some((selected) => selected._id === option.value))
                                    )}
                                    onChange={handleOnchangeLocation}
                                    placeholder="Select Location"
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Course Classes</label>
                        <Select
                            isMulti
                            className="multi-select w-100 mb-3"
                            value={(Array.isArray(courseClasses) ? courseClasses : []).map(cls => {
                                // If location has _id, find the corresponding title
                                const clsTitle = cls._id
                                    ? classSectionOptions.find(option => option.value === cls._id)?.label
                                    : classSectionOptions.find(option => option.value === cls)?.label;

                                return { label: clsTitle, value: cls._id || cls }; // Return object with label and value
                            })}
                            options={classSectionOptions.filter(
                                (option) => !(Array.isArray(courseClasses) && courseClasses.some((selected) => selected._id === option.value)) // Exclude selected items
                            )}
                            onChange={handleOnchange}
                            placeholder="Select Class"
                        />
                        {/*<MultiSelect
                            className="multi-select w-100"
                            onChange={handleOnchange}
                            options={classSectionOptions}
                            customValue={true}
                            value={courseClasses}
                        />*/}
                        {/*<span>
                            {courseClasses && courseClasses.map((cls, index) => (
                                <span key={index}>{cls._id}{index < courseClasses.length - 1 ? ', ' : ''}</span>
                            ))}
                        </span>*/}
                        {/*<span>
                          {courseClasses && courseClasses.length > 0 && courseClasses.map((cls, index) => {
                            let classTitle;

                            if (cls._id) {
                              // If class has _id, find the title using _id
                              classTitle = classSectionOptions.find(option => option.value === cls._id)?.label || 'Unknown';
                            } else {
                              // If class doesn't have _id, use the whole class object for matching
                              classTitle = classSectionOptions.find(option => option.value === cls)?.label || 'Unknown';
                            }

                            return (
                              <span key={index}>
                                {classTitle}
                                {index < courseClasses.length - 1 ? ', ' : ''}
                              </span>
                            );
                          })}
                        </span>*/}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                    <div className="form-group text-end">
                      <button onClick={handleSubmit} type="submit" className="m-1 btn btn-secondary">Update</button>
                      <button onClick={handleCourseFormModalClose} className="m-1 btn btn-secondary">Close</button>
                    </div>
                  </Modal.Footer>
      </Modal>
		</>
	)
}

export default CourseFormModal