import React,{useEffect,useState,useRef} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { logout, RESET } from "../../redux/crm-features/auth/authSlice";
import { UserProfile,UserName,UserRole } from "../pages/ProfileAccount";
import { SuperAdminLink } from "./hiddenLinks";
import {BiCog} from 'react-icons/bi';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import {
  	getModules, 
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import DropdownButton from 'react-bootstrap/DropdownButton';

const activeLink = ({isActive}) => ( isActive ? 'active' : '' );

const Header = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { moduleItems } = useSelector(
	    (state) => state.moduleItem
	);

	useEffect(() => {
	    dispatch(getModules());
	}, [dispatch]);

	const logoutUser = async () => {
	    dispatch(RESET());
	    await dispatch(logout());
	    navigate("/crm/login");
	};

	// const [checkedList, setCheckedList] = useState([]);
	// const [menuList, setMenuList] = useState(moduleItems);
	
  //   const checkBoxClick = (e, item) => {
		// // console.log(item)
  //   	const isChecked = e.target.checked;
  //   	if (isChecked === true) {
	 //      //Add checked item into checkList
	 //      setCheckedList([...checkedList, item]);
	 //      sessionStorage.setItem('checkedList', JSON.stringify(checkedList));
	 //      // console.log(checkedList)
	 //      // localStorage.setItem(checkedList, value);

	 //    } else if (isChecked === false){
	 //      //Remove unchecked item from checkList
	 //      const filteredList = checkedList.filter((x) => x !== item);
	 //      setCheckedList([...filteredList]);
  //   	}
	 //      console.log(checkedList);

  //   	// var checkClick = document.getElementsByName('module_check_list');
  //   	// var newClick = 0;
  //   	// var count ;
  //   	// for(count=0;count<checkClick.length;count++){
  //   	// 	if (checkClick[count].checked) {
  //   	// 		newClick = newClick + 1;
  //   	// 	}
  //   	// }
  //   	// if (newClick > 5) {
  //   	// 	document.getElementById('not').innerHTML='**** Please select only Four';
  //   	// 	return false;
  //   	// }
  //   }

  // const [checkedList, setCheckedList] = useState(() => {
  //       const storedCheckedList = sessionStorage.getItem('checkedList');
  //       return storedCheckedList ? JSON.parse(storedCheckedList) : [];
  //   });

//   const checkBoxClick = (e, item) => {
//         const isChecked = e.target.checked;

//         if (isChecked) {
//             setCheckedList(prevList => [...prevList, item]);
//         } else {
//             setCheckedList(prevList => prevList.filter(x => x !== item));
//         }
//     };

// useEffect(() => {
//         sessionStorage.setItem('checkedList', JSON.stringify(checkedList));
//     }, [checkedList]);

// const checkBoxClick = (e, item) => {
//     const isChecked = e.target.checked;

//     if (isChecked) {
//         setCheckedList(prevList => {
//             const updatedList = [...prevList, item];
//             sessionStorage.setItem('checkedList', JSON.stringify(updatedList));
//             return updatedList;
//         });
//     } else {
//         setCheckedList(prevList => {
//             const updatedList = prevList.filter(x => x !== item);
//             sessionStorage.removeItem('checkedList', JSON.stringify(updatedList));
//             return updatedList;
//         });
//     }
// };

// const checkBoxClick = (e, item) => {
//     const isChecked = e.target.checked;

//     // Update checkedList based on isChecked
//     setCheckedList(prevList => {
//         let updatedList;
//         if (isChecked) {
//             updatedList = [...prevList, item];
//         } else {
//             // Remove the item from checkedList based on its _id
//             updatedList = prevList.filter(x => x._id !== item._id);
//         }

//         // Update sessionStorage with the updated checkedList
//         sessionStorage.setItem('checkedList', JSON.stringify(updatedList));
//         return updatedList;
//     });
// };

 // const [checkedList, setCheckedList] = useState([]);

 // useEffect(() => {
        
 //        const storedCheckedList = localStorage.getItem('checkedList');
 //        if (storedCheckedList) {
 //            setCheckedList(JSON.parse(storedCheckedList));
 //        }
 //        // console.log(storedCheckedList);
 //    }, []);

 // const getTitleById = (id) => {
 //        const item = Array.isArray(moduleItems) ? moduleItems.find(item => item._id === id) : null;
 //        return item ? item.title : '';
 //    };

 // const checkBoxClick = (e, item) => {
 //        const isChecked = e.target.checked;
 //        const itemId = item._id;

 //        setCheckedList(prevList => {
 //            let updatedList;
 //            if (isChecked) {
 //                updatedList = [...prevList, itemId];
 //            } else {
 //                updatedList = prevList.filter(id => id !== itemId);
 //            }
 //            localStorage.setItem('checkedList', JSON.stringify(updatedList));
 //            return updatedList;
 //        });
 //    };

 // 	const [list1, setList1] = useState(() => {
 //        const storedList1 = localStorage.getItem('list1');
 //        return storedList1 ? JSON.parse(storedList1) : [
 //            { _id: 1, title: "Item 1" },
 //            { _id: 2, title: "Item 2" },
 //            { _id: 3, title: "Item 3" },
 //            { _id: 4, title: "Item 4" },
 //        ];
 //    });

 //    const [list2, setList2] = useState(() => {
 //        const storedList2 = localStorage.getItem('list2');
 //        return storedList2 ? JSON.parse(storedList2) : [
 //            { _id: 11, title: "Item 11" },
 //            { _id: 12, title: "Item 12" },
 //            { _id: 13, title: "Item 13" },
 //            { _id: 14, title: "Item 14" },
 //        ];
 //    });
	// const handleSwap = (index, sourceList, targetList, setSourceList, setTargetList) => {
 //        const updatedSourceList = [...sourceList];
 //        const updatedTargetList = [...targetList];
 //        const temp = updatedSourceList[index];
 //        updatedSourceList[index] = updatedTargetList[updatedTargetList.length - 1];
 //        updatedTargetList[updatedTargetList.length - 1] = temp;
 //        setSourceList(updatedSourceList);
 //        setTargetList(updatedTargetList);
 //    };

 //    useEffect(() => {
 //        localStorage.setItem('list1', JSON.stringify(list1));
 //    }, [list1]);

 //    useEffect(() => {
 //        localStorage.setItem('list2', JSON.stringify(list2));
 //    }, [list2]);

 

 //    const [menuList, setMenuList] = useState(() => {
 //        const storedMenuList = localStorage.getItem('menuList');
 //        return storedMenuList ? JSON.parse(storedMenuList) : moduleItems;
 //    });
	// useEffect(() => {
 //        setMenuList(moduleItems);
 //    }, [moduleItems]);
 //    // console.log(menuList);
 //    useEffect(() => {
 //        localStorage.setItem('menuList', JSON.stringify(menuList));
 //    }, [menuList]);
    // useEffect(() => {
    //     localStorage.setItem('menuList', JSON.stringify(moduleItems));
    // }, [moduleItems]);
    // const handleSwap = (index) => {
    //     const updatedMenuList = [...menuList];
    //     const lastIndex = updatedMenuList.length - 1;
    //     const temp = updatedMenuList[lastIndex];
    //     updatedMenuList[lastIndex] = updatedMenuList[index];
    //     updatedMenuList[index] = temp;
    //     setMenuList(updatedMenuList); // Update menuList state
    // };

//     const [listItems, setListItems] = useState(moduleItems);
// useEffect(() => {
//         setListItems(moduleItems);
//     }, [moduleItems]);
// console.log(listItems);


    const [list1, setList1] = useState(() => {
    // Get list from localStorage if available, or use default list
    const storedList = localStorage.getItem('list1');
    return storedList ? JSON.parse(storedList) : moduleItems;
  });
    useEffect(() => {
      setList1(moduleItems); 
  }, [moduleItems]);
useEffect(() => {
    localStorage.setItem('list1', JSON.stringify(list1));
  }, [list1]);
    const swapItems = (id) => {
    const firstItem = list1[4];
    const itemIndex = list1.findIndex(item => item._id === id);
    if (itemIndex !== -1) {
      const newList = [...list1];
      newList[4] = list1[itemIndex];
      newList[itemIndex] = firstItem;
      setList1(newList);
      console.log(newList);
    }
  };

  // const [activeItem, setActiveItem] = useState(() => {
  //       const savedItem = localStorage.getItem('activeItem');
  //       return savedItem !== null ? JSON.parse(savedItem) : null;
  //   });

  // useEffect(() => {
  //       localStorage.setItem('activeItem', JSON.stringify(activeItem));
  //   }, [activeItem]);

  //   const handleItemClick = (index) => {
  //       setActiveItem(index);
  //   };

    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    // const firstSixItems = Array.isArray(list1) ? list1.slice(0, 6) : null;
	// const remainingItems = Array.isArray(list1) ? list1.slice(6) : null;

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (dropdownRef.current) {
            dropdownRef.current.setAttribute('data-bs-toggle', 'dropdown');
        }
    }, []);

	return (
		<header>
			{/* <!--  BEGIN NAVBAR  -->*/}
		    <div className="header-container container-xxl">
		        <header className="header navbar navbar-expand-sm expand-header" style={{justifyContent : 'space-between'}}>

		            <ul className="navbar-item theme-brand flex-row  text-center">
		                <li className="nav-item theme-logo">
		                    <Link to="/crm">
		                    	<h3>KBM</h3>
		                    	<small>SYSTEM</small>
		                        {/*<img src="../src/assets/img/kbmlogo.png" className="navbar-logo" alt="KBM_LOGO"/>*/}
		                    </Link>
		                </li>
		                
		            </ul>

		            <ul className="navbar-item custom_navbar flex-row">
		            	<li className={`nav-item ${isActive("/crm") ? 'active' : ''}`}>
		                    <Link to="/crm" className="nav-link p-0">Home</Link>
		                </li>
		                {/*{
		                	checkedList && checkedList.slice(0,6).map((item, i) =>(
		                		<li className="nav-item" key={i}>
				                    <Link to={`/crm/module-record/${item}`} className="nav-link p-0 text-capitalize">{getTitleById(item)}</Link>
				                </li>
		                		))
		                }*/}
		                {/*{
		                	moduleItems && moduleItems.slice(0,6).map((item, i) =>(
		                		<li className="nav-item" key={i}>
				                    <Link to={`/crm/module-record/${item._id}`} className="nav-link p-0 text-capitalize">{item.title}</Link>
				                </li>
		                		))
		                }*/}
		                {/*{firstSixItems.map((item, index) => (
		                    <li className="nav-item" key={index}>
		                        <Link to={`/crm/module-record/${item._id}`} className="nav-link p-0 text-capitalize">{item.title}</Link>
		                    </li>
		                ))}*/}
		                {
			              		
								 Array.isArray(list1) && list1.slice(0,6).map((item, index)=>{

					            	return(
				                		<li key={index} className={`nav-item ${isActive(`/crm/module-record/${item._id}`) ? 'active' : ''}`}>
				                			<Link to={`/crm/module-record/${item._id}`} className="nav-link p-0 text-capitalize">{item.title}</Link>
				                		</li>
				                		)
				            		})
								 
			            	}
		                {/*{menuList.map((item, index) => {
						    if (index >= menuList.length / 2) {
						        return (
						            <li className="nav-item" key={item._id}>
						                <Link to={`/crm/module-record/${item._id}`} className="nav-link p-0 text-capitalize">{item.title}</Link>
						            </li>
						        );
						    }
						    return null;  // Don't render before the halfway point
						})}*/}
		                <li className={`nav-item ${isActive("/crm/reports") ? 'active' : ''}`}>
		                    <Link to="/crm/reports" className="nav-link p-0">Reports</Link>
		                </li>
		                <li className={`nav-item ${isActive("/crm/all-courses") ? 'active' : ''}`}>
		                		<Link to="/crm/all-courses"  className="nav-link p-0">Teachable</Link>
		                </li>
						<li className={`nav-item ${isActive("/crm/courses-main-page") ? 'active' : ''}`}>
							<Link to="/crm/courses-main-page" className="nav-link p-0">Courses View</Link>
		                </li>
		                <li>
			                <div className="custom-dropdown-menu">
								        {['end'].map(
								          (direction) => (
								            <DropdownButton
								              key={direction}
								              id={`dropdown-button-drop-${direction}`}
								              drop={direction}
								              title={`...`}
								            >
								            <li>
											        <form className="d-flex">
											          <div className="checkSearchBox">
											            <button className="btn" type="submit"><AiOutlineSearch /></button>
											            <input className="form-control" type="search" placeholder="Search" aria-label="Search"/>
											          </div>
											        </form>
											      	<hr className="my-2 divider"/>
						              	</li>
								              {
								              		Array.isArray(list1) && list1.slice(6).map((item, index)=>{

										            	return(
									                		<li key={index} className="module_check_list">
									                			<span className="module_check_list mt-1" key={item._id} onClick={() => swapItems(item._id)}>{item.title}</span>
									                		</li>
									                		)
									            		})
													 
								            	}
								            </DropdownButton>
								          ),
								        )}
								      </div>
							      </li>
		                {/*<li className="dropdown">
			              <span className="dropdown-toggle" role="button" data-bs-toggle="dropdown"><AiOutlineEllipsis size={22}/></span>
			              <ul className="dropdown-menu custom-dropdown-menu">
			              	<li>
			              		
						        <form className="d-flex">
						          <div className="checkSearchBox">
						            <button className="btn" type="submit"><AiOutlineSearch /></button>
						            <input className="form-control" type="search" placeholder="Search" aria-label="Search"/>
						          </div>
						        </form>
						      	<hr className="my-2 divider"/>
			              	</li>
			              	
			              	{
			              		Array.isArray(list1) && list1.slice(6).map((item, index)=>{

					            	return(
				                		<li key={index} className="module_check_list">
				                			<span className="module_check_list mt-1" key={item._id} onClick={() => swapItems(item._id)}>{item.title}</span>
				                		</li>
				                		)
				            		})
								 
			            	}*/}
			            	{/*{Array.isArray(moduleItems) && moduleItems.length > 0 && moduleItems.slice(-6).map((item, i) => (
			                    <li key={i} className="module_check_list mt-1">
			                        <span >{item.title}</span>
			                    </li>
			                ))}*/}
			                {/*{remainingItems.map((item, index) => (
					          <li className="module_check_list mt-1" key={item._id} onClick={() => swapItems(item._id)}>{item.title}</li>
					        ))}*/}
			            	{/*{menuList.map((item, index) => {
							    if (index < menuList.length / 2) {
							        return (
							            <li className="module_check_list mt-1" key={item._id} onClick={() => handleSwap(index)}>
							                {item.title}
							            </li>
							        );
							    }
							    return null;  // Don't render beyond the halfway point
							})}*/}
			              {/*</ul>
			            </li>*/}

		            </ul>

		            <ul className="navbar-item flex-row ms-auto ms-0 action-area">

		            	{/*<li className="nav-item theme-toggle-item">
		            		<div className="search-animated toggle-search">
				                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
				                <form className="form-inline search-full form-inline search" role="search">
				                    <div className="search-bar">
				                        <input type="text" className="form-control search-form-control  ml-lg-auto" placeholder="Search..."/>
				                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x search-close"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
				                    </div>
				                </form>
				                <span className="badge badge-secondary">Ctrl + /</span>
				            </div>
		            	</li>*/}

		                <li className="nav-item theme-toggle-item">
		                    <Link to="/crm/modules" className="nav-link">
		                    	<BiCog size={24} color='#e0e6ed' />
		                    </Link>
		                </li> 

		                {/*<li className="nav-item theme-toggle-item">
		                    <Link to="" className="nav-link theme-toggle">
		                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon dark-mode"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
		                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun light-mode"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
		                    </Link>
		                </li>*/}

		                <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
		                    <div ref={dropdownRef} className="nav-link dropdown-toggle user" id="userProfileDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{cursor : 'pointer'}}>
		                        <div className="avatar-container">
		                            <div className="avatar avatar-sm avatar-indicators avatar-online">
		                                <UserProfile />
		                            </div>
		                        </div>
		                    </div>

		                    <div className="dropdown-menu position-absolute " aria-labelledby="userProfileDropdown">
		                        <div className="user-profile-section">
		                            <div className="media mx-auto">
		                                <div className="emoji me-2">
		                                    &#x1F44B;
		                                </div>
		                                <div className="media-body">
		                                    <UserName />
		                                    <UserRole />
		                                </div>
		                            </div>
		                        </div>
		                        <div className="dropdown-item">
		                            <Link to="/crm/profile-account">
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Profile</span>
		                            </Link>
		                        </div>
		                        <div className="dropdown-item">
		                            <Link to="/crm/change-password">
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Change Password</span>
		                            </Link>
		                        </div>
		                        <SuperAdminLink>
		                        <div className="dropdown-item">
		                            <Link to="/crm/master-data">
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> <span>Master Data</span>
		                            </Link>
		                        </div>
		                        </SuperAdminLink>
		                        <div className="dropdown-item">
		                            <Link to="" onClick={logoutUser}>
		                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> <span>Log Out</span>
		                            </Link>
		                        </div>
		                    </div>
		                    
		                </li>
		            </ul>
		        </header>
		    </div>
		    {/*<!--  END NAVBAR  -->*/}
		</header>
	)
}

export default Header