import React,{useState,useRef,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import { toast } from "react-toastify";
import { Link,useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import Footer from '../components/Footer';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Editor } from '@tinymce/tinymce-react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { uploadCourseFile, uploadCourseVideo } from "../../redux/crm-features/course/courseSlice";
import {
  selectCourse,
  // getCourse,
}   from "../../redux/crm-features/course/courseSlice";

const CourseContent = () => {
	useRedirectLoggedOutUser("/crm/login");
	
	const { courseId, panelId } = useParams();
    const [data, setData] = useState({ course: null, panel: null });
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

	const dispatch = useDispatch();

	// const { course } = useSelector(
    //   (state) => state.course
  	// );

  // 	useEffect(() => {
  //     dispatch(getCourse(id));
  // }, [dispatch, id]);

	const [textImageModal, SetTextImageModalShow] = useState(false)
	const handleTextImageModalShow = () => { SetTextImageModalShow(true) }
	const hanldeTextImageModalClose = () => { SetTextImageModalShow(false) }

	const [fileViewerModal, SetFileViewerModalShow] = useState(false)
	const handleFileViewerModalShow = () => { SetFileViewerModalShow(true) }
	const hanldeFileViewerModalClose = () => { SetFileViewerModalShow(false);setFile(null); }

	const [videoViewerModal, SetVideoViewerModalShow] = useState(false)
	const handleVideoViewerModalShow = () => { SetVideoViewerModalShow(true) }
	const hanldeVideoViewerModalClose = () => { SetVideoViewerModalShow(false) }

	const [quizModal, SetQuizModalShow] = useState(false)
	const handleQuizModalShow = () => { SetQuizModalShow(true) }
	const hanldeQuizModalClose = () => { SetQuizModalShow(false) }

	const [editorContent, setEditorContent] = useState("");

	const handleEditorChange = (courseContent, editor) => {
    	// console.log('Content was updated:', courseContent);
    	setEditorContent(courseContent)
  	};

  const [videoUrl, setVideoUrl] = useState('');

  const handleVideoChange = (event) => {
  const file = event.target.files[0];
  setVideoUrl(file); // Store the file, not the URL
};

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const [sections, setSections] = useState([]);

	useEffect(() => {
	    // Initialize with one section when the component mounts
	    addSection();
	}, []);

	const addSection = () => {
	    const newSection = {
	      id: sections.length,
	      panels: [] // Each section starts with an empty list of panels
	    };
	    setSections([...sections, newSection]);
	};

	const deleteSection = (sectionId) => {
    	setSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  	};

	const addPanel = (sectionId, panelContent) => {
	    setSections(prevSections => {
	      return prevSections.map(section => {
	        if (section.id === sectionId) {
	          // Add a new panel to the specified section
	          return {
	            ...section,
	            panels: [...section.panels, { id: section.panels.length, content: panelContent }]
	          };
	        }
	        return section;
	      });
	    });
	};

	const deletePanel = (sectionId, panelId) => {
	    setSections(prevSections => {
	        return prevSections.map(section => {
	            if (section.id === sectionId) {
	                // Filter out the panel with the specified panelId
	                const updatedPanels = section.panels.filter(panel => panel.id !== panelId);
	                // Return the updated section with filtered panels
	                return {
	                    ...section,
	                    panels: updatedPanels
	                };
	            }
	            return section;
	        });
	    });
	};

	const handleDragStart = (event, section) => {
  event.dataTransfer.setData('section', JSON.stringify(section));
};

const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
  const newSections = [...sections];
  const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
  newSections.splice(currentIndex, 1);
  newSections.splice(targetIndex, 0, droppedItem);
  setSections(newSections);
};

const uploadFileToS3 = async (e) => {
    // e.preventDefault();
    // if (!file) {
    //     toast.error("Please select a file before uploading.");
    //     return; // Stop the function if no video is selected
    // }
    // const courseFileData = new FormData();
    // courseFileData.append('file', file);
    // // console.log(courseFileData)
    // dispatch(uploadCourseFile({courseId,panelId,courseFileData}));
    // forceUpdate();
    // hanldeFileViewerModalClose();
    e.preventDefault();
        if (file) {
        	/*if (file.type !== 'application/pdf') {
	            toast.error("Only PDF files are allowed.");
	            return; // Stop further execution if the file is not a PDF
	        }*/
            // setLoading(true);
            const courseFileData = new FormData();
            courseFileData.append('file', file);  // Append the actual file object

            try {
                const response = await axios.post(
		            `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/uploadCourseFile/${courseId}/${panelId}`,
		            courseFileData,
		            {
		                headers: {
		                    'Content-Type': 'multipart/form-data', // Required for file uploads
		                },
		            }
		        );
		        console.log("Uploaded file MIME type:", file.type);
                // Handle success (response contains the uploaded media)
                toast.success('File uploaded successfully');
                forceUpdate();
                hanldeFileViewerModalClose(); // Close the modal after upload
            } catch (error) {
                console.log('Error uploading file:', error);
            } 
        } else {
            console.log("No video file selected");
        }

};

const uploadVideoToS3 = async (e) => {
    // e.preventDefault();
    // if (!videoUrl) {
    //     toast.error("Please select a video before uploading.");
    //     return; // Stop the function if no video is selected
    // }
    // const courseFileData = new FormData();
    // courseFileData.append('file', videoUrl);
    // // console.log(courseFileData)
    // dispatch(uploadCourseVideo({courseId,panelId,courseFileData}));
    // forceUpdate();
    // hanldeVideoViewerModalClose();
    e.preventDefault();
        if (videoUrl) {
            // setLoading(true);
            const courseFileData = new FormData();
            courseFileData.append('file', videoUrl);  // Append the actual file object

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/uploadCourseVideo/${courseId}/${panelId}`,
                    courseFileData
                );
                // Handle success (response contains the uploaded media)
                toast.success('File uploaded successfully');
                // toast.success('File uploaded successfully:', response.data);
                forceUpdate();
                hanldeVideoViewerModalClose(); // Close the modal after upload
            } catch (error) {
                console.log('Error uploading file:', error);
            } 
        } else {
            console.log("No video file selected");
        }
};

const handleSubmit = async () => {
    try {
        const { data } = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/updateCourseContent/${courseId}/${panelId}`, // Replace with actual courseId & panelId
            { content: editorContent }, // Send only the editor content
            {
                headers: { "Content-Type": "application/json" },
            }
        );
        console.log("Content Updated:", data);
        toast.success('Content uploaded successfully');
        forceUpdate();
        hanldeTextImageModalClose();
    } catch (error) {
        console.error("Error Updating Content:", error);
    }
};

	useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/course/crm/${courseId}/${panelId || ''}`);
                setData(response.data);
                // console.log('Fetched data:', response.data); 
                
            } catch (error) {
                console.log(
				      "Error fetching course item:",
				      error.response?.data || error.message
				    );
            }
        };

        fetchData();
    }, [courseId, panelId, reducerValue]);

    const handleDeleteMedia = async (mediaId) => {
  // setLoading(true); // Indicate the operation is in progress
  // setError(null); // Clear any existing error messages

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/course/crm/${courseId}/panel/${panelId}/media/${mediaId}`
    );

    if (response.status === 200) {
      // Update the media list in the component
      data.panel.media = data.panel.media.filter((item) => item._id !== mediaId);
    }
    toast.success('Deleted successfully');
    forceUpdate();
  } catch (err) {
    // setError("Failed to delete media. Please try again.");
    console.error("Error deleting media:", err);
  }
};

const generateS3Url = (url) => {
  const generatedUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/uploads/${url}`;
  // console.log("Generated URL:", generatedUrl);
  return generatedUrl;
};
// console.log(process.env.S3_BUCKET_NAME || process.env.REACT_APP_S3_BUCKET_NAME)

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
			<div className="main-container" id="container">

				<div className="overlay"></div>
				<div className="search-overlay"></div>

				<Sidebar/>

				{/*<!--  BEGIN CONTENT AREA  -->*/}
				<div id="content" className="main-content">
					<div className="layout-px-spacing">

						<div className="middle-content container-xxl p-0">

							<Breadcrumb/>

							<div className="row layout-top-spacing">

								<div className="courseCurriculumHeading col-12">
									<span><Link to="/crm/all-courses">Course</Link> / <Link to={`/crm/course-from-scratch/${data.course?._id}`}>{data.course?.courseTitle}</Link> / {data.panel?.title}</span>
									<h3>Curriculum</h3>

								</div>

								<div className="lessonCOntentSec col-md-8 col-sm-12 col-12">
									<div className="lessonCOntent">
										<div className="lessonCOntentHead">
											<h4>{data.panel?.title}</h4>
											<button type="submit" className="btn btn-secondary mx-1">Preview</button>
											<div className="lessonCOntentDropdown">
												{['end'].map(
													(direction) => (
														<DropdownButton
															key={direction}
															id={`dropdown-button-drop-${direction}`}
															drop={direction}
															title={<i className="fa fa-ellipsis-v"></i>}
														>
															<li>
																<span>Rename Lesson</span>
															</li>
															<li>
																<span>Delete Lesson</span>
															</li>

														</DropdownButton>
													),
												)}
											</div>
										</div>
										<div className="lessonContentBody">
											<h5 style={{textAlign : 'left'}}>Content</h5>
											<p style={{textAlign : 'left'}} dangerouslySetInnerHTML={{ __html: data.panel?.content }} />
											{data.panel && data.panel.media && data.panel.media.length > 0 ? (
											    <div>
											        <h5 style={{ textAlign: 'left' }}>Media</h5>
											        <div>
											            {data.panel.media.map((item, index) => (
											                <div key={index} style={{ position: 'relative' }}>
											                    {/* Dropdown Menu */}
											                    <div className="dropdown" style={{ float: 'right', margin: '10px' }}>
											                        <button
											                            className="btn btn-secondary dropdown-toggle"
											                            type="button"
											                            id={`dropdownMenuButton-${index}`}
											                            data-bs-toggle="dropdown"
											                            aria-expanded="false"
											                        >
											                            Options
											                        </button>
											                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${index}`}>
											                            <li>
											                                <a className="dropdown-item" href={item.url} target="_blank" rel="noopener noreferrer">
											                                    View
											                                </a>
											                            </li>
											                            <li>
											                                <a className="dropdown-item" href={item.url} download>
											                                    Download
											                                </a>
											                            </li>
											                            <li>
											                                <button className="dropdown-item" onClick={() => handleDeleteMedia(item._id)}>
											                                    Delete
											                                </button>
											                            </li>
											                        </ul>
											                    </div>

											                    {/* Media Rendering */}
											                    {item.type === 'video' ? (
											                        <div style={{ backgroundColor: "white" }} className="my-3 p-3">
											                            <h6 className="text-left" style={{ textAlign: 'left' }}>Video {index + 1}</h6>
											                            <video width="50%" controls>
											                                <source src={item.url} type="video/mp4" />
											                                Your browser does not support the video tag.
											                            </video>
											                        </div>
											                    ) : item.type === 'image' ? (
											                        <div style={{ backgroundColor: "#f5f5f5" }} className="my-3 p-3">
											                            <h6 className="text-left" style={{ textAlign: 'left' }}>Image {index + 1}</h6>
											                            <img src={item.url} alt={`Media ${index + 1}`} width="50%" />
											                        </div>
											                    ) : (
											                        <div style={{ backgroundColor: '#f5f5f5' }} className="my-3 p-3">
											                            <h6 className="text-left" style={{ textAlign: 'left' }}>Document {index + 1}</h6>
											                            <DocViewer
											                                documents={[{ uri: generateS3Url(item.url) }]}
											                                pluginRenderers={DocViewerRenderers}
											                            />
											                        </div>
											                    )}
											                </div>
											            ))}
											        </div>
											    </div>
											) : (
											    <>
											        <h5>You've got knowledge, it's time to share it!</h5>
											        <button type="submit" className="btn btn-secondary mt-3">Add Content</button>
											    </>
											)}

										</div>
									</div>
								</div>
								<div className="addContentSec col-md-4 col-sm-12 col-12">
									<div className="addContentOption">
										<h4>Add Content</h4>
										<ul>
											<li onClick={() => {
												handleTextImageModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-align-left"></i>
			                      				Text & Images
			                      			</span>
											</li>
											<li onClick={() => {
												handleVideoViewerModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-youtube-play"></i>
			                      				Video
			                      			</span>
											</li>
											<li onClick={() => {
												handleFileViewerModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-file-text-o"></i>
			                      				File Preview
			                      			</span>
											</li>
											<li onClick={() => {
												handleQuizModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-table"></i>
			                      				Quiz
			                      			</span>
											</li>
										</ul>
									</div>
								</div>

							</div>

						</div>

					</div>

					<Footer/>

				</div>
				{/*<!--  END CONTENT AREA  -->*/}

			</div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

		    {/*<!-- Modal -->*/}
		    <Modal show={textImageModal}
                    onHide={hanldeTextImageModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Text and images block</h4>	
                    </Modal.Title>
                    <span onClick={hanldeTextImageModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
					<div className="customClassicEditor">
						<Editor
							apiKey='511npkfmj2oio25sykoybbl32gny8yfwq0zbl3m5zrkzqoz7'
							//initialValue="<p>This is the initial content of the editor</p>"
							init={{
								height: 500,
								menubar: true,
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar:
									'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help',
                                    image_uploadtab: true,
							        image_advtab: true, // Enable advanced tab for images
							        file_picker_types: 'image',
							        automatic_uploads: true,
							}}

							onEditorChange={handleEditorChange}
						/>
					</div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={handleSubmit} className="m-1 btn btn-secondary">Upload</button>
						<button onClick={hanldeTextImageModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={fileViewerModal}
                    onHide={hanldeFileViewerModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>File Upload</h4>	
                    </Modal.Title>
                    <span onClick={hanldeFileViewerModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
      							<div>
								      <input type="file" onChange={handleFileChange} />
								      {file && (
								        <div>
								          <DocViewer documents={[{ uri: URL.createObjectURL(file) }]} pluginRenderers={DocViewerRenderers} />
								        </div>
								      )}
								    </div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={uploadFileToS3} className="m-1 btn btn-secondary">Upload</button>
						<button onClick={hanldeFileViewerModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={videoViewerModal}
                    onHide={hanldeVideoViewerModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Video Upload</h4>	
                    </Modal.Title>
                    <span onClick={hanldeVideoViewerModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
									<div>
							      <input type="file" name="file" accept="video/*" onChange={handleVideoChange} />
							      {videoUrl && (
							        <>
							          <video
							          	width='100%'
							            src={videoUrl}
							            controls
							            // autoPlay={true}
							          />
							        </>
							      )}
							    </div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={uploadVideoToS3} className="m-1 btn btn-secondary">Upload</button>
						<button onClick={hanldeVideoViewerModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={quizModal}
                    onHide={hanldeQuizModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Quiz block</h4>	
                    </Modal.Title>
                    <span onClick={hanldeQuizModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
						<div className="">
						{sections.map((section, sectionIndex) => (
							<div key={section.id} className="quizSec section" draggable
        onDragStart={(event) => handleDragStart(event, section)}
        onDragOver={(event) => handleDragOver(event)}
        onDrop={(event) => handleDrop(event, sectionIndex)}>
					          	<div>
          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
					          	</div>
          						<div className="dndQuizSec">
								<div className="quizQuestion">
									<div className="d-flex align-items-center justify-content-between">
										<h6>QUESTION</h6>
										<ul className="quizHeadBtn">
											<li><button className="m-1 btn btn-secondary">Done</button></li>
											<li onClick={() => deleteSection(section.id)}><button className="m-1 btn btn-secondary"><i className="fa fa-trash-o"></i></button></li>
										</ul>
									</div>
									<div className="col-md-12">
		                                <div className="form-group">
		                                    <input type="text" className="form-control mt-3" placeholder="Write your question here" />
		                                </div>
		                            </div>
								</div>
						    	<div className="quizAnswer">
									<p>ANSWERS Choose single or multiple correct answers</p>
									{section.panels.map((panel, index) => (
							        <div key={panel.id} className="panel d-flex align-items-center">
							        <div>
	          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
						          	</div>
									<div className="quizAnswerInput">
										<div className="form-group w-100">
		                                    <input type="text" className="form-control" placeholder="Write your question here" />
		                                </div>
		                                <input className="form-check-input" type="checkbox" id="check1" name="option1"/>
		                                <button onClick={() => deletePanel(section.id, panel.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
									</div>
									</div>
							        ))}
									<div className="addAnswer" onClick={() => addPanel(section.id, `Panel ${section.panels.length}`)}>
										<i className="fa fa-plus-circle"></i><span>Add Answer</span>
									</div>
								</div>
								</div>
							</div>
						))}
						</div>
						<div className="addQuiz" onClick={addSection}>
							<i className="fa fa-plus-circle"></i><span> New Question</span>
						</div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={hanldeQuizModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>


		</>
	)
}

export default CourseContent